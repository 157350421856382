<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础管理</el-breadcrumb-item>
      <el-breadcrumb-item>协议隐私</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="1.5">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加</el-button
          >
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="类型" prop="type"
          ><template slot-scope="scope">
            <el-tabs v-if="scope.row.type == 1">用户协议</el-tabs>
            <el-tabs v-if="scope.row.type == 2">隐私政策</el-tabs>
          </template></el-table-column
        >
        <el-table-column
          label="内容"
          prop="content"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
            ></el-button>
            <!-- 删除 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteUser(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 创建新用户对话框 -->
    <el-dialog
      title="添加信息"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="70px"
      >
        <el-form-item label="类型" prop="type">
          <el-select
            :data="isProductShelvesList"
            placeholder="类型"
            v-model="addForm.type"
          >
            <el-option
              v-for="item in isProductShelvesList"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="内容" prop="content">
          <el-input v-model="addForm.content"></el-input>
        </el-form-item>-->
        <el-form-item label="内容">
          <quill-editor :options="editorOption" v-model="addForm.content">
          </quill-editor>
        </el-form-item>
      </el-form>
      <!-- 内容底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改信息"
      :visible.sync="editDialogVisible"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="70px"
      >
        <el-form-item label="类型" prop="type">
          <el-select
            disabled
            :data="isProductShelvesList"
            placeholder="类型"
            v-model="editForm.type"
          >
            <el-option
              v-for="item in isProductShelvesList"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容">
          <quill-editor :options="editorOption" v-model="editForm.content">
          </quill-editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content1: "",
      editorOption: {
        placeholder: "请输入正文",
      },
      // 请求数据
      queryInfo: {
        userName: "",
        query: "",
        realName: "",
        pageNo: 1,
        pageSize: 10,
      },
      queryRoleInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      isProductShelvesList: [
        { key: "1", display_name: "用户协议" },
        { key: "2", display_name: "隐私政策" },
      ],
      shorelist: [],
      userlist: [], // 用户列表
      rolelist: [], // 角色列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {
        userName: "",
        passWord: "",
        roleId: "",
        storeId: "",
      },
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        userName: "",
        passWord: "",
        roleId: "",
        storeId: "",
      },

      deFlag: "",
      // 验证规则
      addFormRules: {
        type: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        type: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    submit() {
      console.log(this.$refs.text.value);
    },
    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/queryPrivacyAgreement",
        this.queryInfo
      );
      console.log("结果：" + res);
      this.userlist = res.resultBody.data; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },
    // 添加用户
    addUser() {
      console.log(this.content1);
      this.$refs.addFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/basic/addPrivacyAgreement",
          this.addForm
        );
        if (res.resultCode != "200") {
          //userinfo.state = !userinfo.state;
          return this.$message.error(res.resultMsg);
        }
        this.$message.success("操作成功！！！");
        //隐藏
        this.addDialogVisible = false;
        this.getUserList();
      });
    },
    // 展示修改框
    async showEditDialog(data) {
      //const {data:res} = await this.$http.get("getUpdate?id="+id);
      // if (res != "success") {
      // userinfo.state = !userinfo.state;
      // return this.$message.error("操作失败！！！");
      // }
      // this.$message.success("操作成功！！！");
      //console.log("修改行信息："+JSON.stringify(data));
      this.editForm = data;
      this.editDialogVisible = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/basic/updatePrivacyAgreement",
          this.editForm
        );
        console.log(res);
        if (res.resultCode != "200")
          return this.$message.error("操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 删除按钮
    async deleteUser(id) {
      // 弹框
      const confirmResult = await this.$confirm(
        "此操作将删除该记录, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 成功删除为confirm 取消为 cancel
      if (confirmResult != "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post(
        "/chemical/basic/deleteDeletePrivacyAgreement?id=" + id
      );
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },
  },
};
</script>
<style lang="less" scoped>
</style>