<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>业务管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row type="flex" class="card" style="width: 100%">
        <el-col class="card-col" style="width: 50%; margin: 0 20px 20px 0">
          <el-card class="box-card">
            <!-- <div slot="header" class="card-header">
              <span>订单概览</span>
            </div> -->
            <div class="card-body">
              <div class="card-body-left">订单总额（元）:</div>
              <div class="card-body-right">
                <a target="_blank">{{ orderTotalPrice }}</a>
              </div>
              <div class="card-body-left">订单总数（个）:</div>
              <div class="card-body-right">
                <a target="_blank">{{ total }}</a>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :span="3">
          <!-- 搜索添加 -->
          <el-input
            placeholder="法人姓名"
            v-model="queryInfo.companyLegalPerson"
            clearable
            @clear="getUserList(1)"
          >
          </el-input>
        </el-col>
        <el-col :span="3">
          <!-- 搜索添加 -->
          <el-input
            placeholder="手机号"
            v-model="queryInfo.phone"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>

        <el-col :span="3">
          <!-- 搜索添加 -->
          <el-input
            placeholder="公司名称"
            v-model="queryInfo.companyName"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <el-col :span="4.5">
          <el-date-picker
            v-model="queryInfo.startTime"
            type="datetime"
            clearable
            @clear="getUserList"
            placeholder="开始时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4.5">
          <el-date-picker
            v-model="queryInfo.endTime"
            type="datetime"
            clearable
            @clear="getUserList"
            placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <!-- 搜索添加 -->
          <el-select
            v-model="queryInfo.orderState"
            clearable
            placeholder="订单状态"
            @clear="getUserList(1)"
          >
            <el-option
              v-for="item in orderStateList"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-col>
        <el-col :span="0.5">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col :span="3">
          <el-button style="margin-top: 15px" icon="el-icon-download" @click="exportFile()">导出</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe ref="singleTable">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="订单号" prop="orderNum" width="180px">
          <template slot-scope="scope">
            <span>{{ scope.row.orderNum }}</span>
            <span>{{ scope.row.orderType == 0 ? "" : "(团购订单)" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下单用户" width="180px" align="center">
          <template slot-scope="scopeData">
            <p v-if="scopeData.row.companyName">{{ scopeData.row.companyName }}</p>
            <p v-if="scopeData.row.phone">{{ scopeData.row.phone }}</p>
          </template>
        </el-table-column>
        <el-table-column
          width="160px"
          label="下单时间"
          prop="createTime"
          :formatter="timestampToTime"
        ></el-table-column>
        <el-table-column label="商品名称">
          <template slot-scope="scopeData">
            <p>{{ scopeData.row.productName + " " + scopeData.row.attr }}</p>
          </template>
        </el-table-column>
        <el-table-column label="订单数量" prop="orderProductNum">
        </el-table-column>
        <el-table-column
          label="货物总金额（元）"
          prop="orderPrice"
          width="130px"
        >
        </el-table-column>
        <el-table-column
          label="运费（元）"
          prop="carriage"
          width="130px"
        ></el-table-column>
        <el-table-column label="订单状态" width="100px" prop="orderState">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.orderState == 1">已下单</el-tabs>
            <el-tabs v-if="scope.row.orderState == 2">已确认订单</el-tabs>
            <el-tabs v-if="scope.row.orderState == 3">已签订合同</el-tabs>
            <el-tabs v-if="scope.row.orderState == 4">已支付</el-tabs>
            <el-tabs v-if="scope.row.orderState == 5">已发货</el-tabs>
            <el-tabs v-if="scope.row.orderState == 6">已完成</el-tabs>
            <el-tabs v-if="scope.row.orderState == 7">已取消</el-tabs>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="姓名"
          prop="companyLegalPerson"
        ></el-table-column> -->
        <!-- <el-table-column label="手机号" prop="phone" width="105px">
        </el-table-column> -->
        <el-table-column label="配送方式" prop="distributionType">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.distributionType == 1">配送上门</el-tabs>
            <el-tabs v-if="scope.row.distributionType == 2">到库自提</el-tabs>
          </template>
        </el-table-column>

        <el-table-column label="地址/自提仓库" prop="orderAddress" width="350px">
          <template slot-scope="scope">
            {{ scope.row.distributionType == 1 ? scope.row.orderAddress : scope.row.name }}
          </template>>
        </el-table-column>
        <el-table-column label="操作" width="280" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              v-if="scope.row.orderState == 1"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="showEditDialogMoney(scope.row)"
              >确认订单</el-button
            >
            <el-button
              v-if="scope.row.orderState == 4"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="showEditDialog(scope.row)"
              >发货</el-button
            >
            <el-button
              v-if="
                scope.row.orderState == 4 ||
                  scope.row.orderState == 5 ||
                  scope.row.orderState == 6
              "
              type="primary"
              size="mini"
              icon="el-icon-notebook-1"
              @click="clickSeeRecord(scope.row)"
              >发货记录</el-button
            >
            <!-- <el-button
              v-if="
                scope.row.orderState == 4 ||
                  scope.row.orderState == 5 ||
                  scope.row.orderState == 6
              "
              type="primary"
              size="mini"
              icon="el-icon-notebook-1"
              @click="orderStateAudit(scope.row, 6)"
              >订单完成</el-button
            > -->
            <el-button
              v-if="scope.row.orderState == 1 || scope.row.orderState == 2"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="orderStateAudit(scope.row, 7)"
              >取消</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <el-dialog
      title="确认订单"
      :visible.sync="editDialogVisibleMoney"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editFormMoney"
        :rules="editFormMoneyRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item
          v-if="editFormMoney.distributionType == 1"
          label="运费（元）"
          prop="carriage1"
        >
          <el-input type="number" v-model="editFormMoney.carriage1"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="orderProductNum">
          <el-input
            type="number"
            v-model="editFormMoney.orderProductNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="总价（元）" prop="orderPrice">
          <el-input type="number" v-model="editFormMoney.orderPrice"></el-input>
        </el-form-item>
        <el-form-item label="配送方式">
          <el-select v-model="editFormMoney.distributionType" placeholder="请选择">
            <el-option
              v-for="item in sendTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选择仓库"
          v-if="editFormMoney.distributionType == '2'"
        >
          <el-select
            placeholder="选择仓库"
            clearable
            v-model="editFormMoney.warehouseId"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.warehouseId"
              :label="item.name"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisibleMoney = false">取 消</el-button>
        <el-button type="primary" @click="updateNaturalOrderCarriage"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="发货"
      :visible.sync="editDialogVisible"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item
          label="选择仓库"
          prop="warehouseId"
          v-if="editForm.distributionType == 1"
        >
          <el-select
            placeholder="选择仓库"
            clearable
            v-model="editForm.warehouseId"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.warehouseId"
              :label="item.name"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="选择仓库"
          prop="warehouseId"
          v-if="editForm.distributionType == 2"
        >
          <el-select
            :disabled="true"
            :data="warehouseList"
            placeholder="选择仓库"
            clearable
            v-model="editForm.warehouseId"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.warehouseId"
              :label="item.name"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货数量" prop="sendCount">
          <el-input
            type="number"
            placeholder="请输入发货数量"
            v-model="editForm.sendCount"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editOrderInfo">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer title="发货记录:" :visible.sync="drawer">
      <div>
        <el-table
          :data="orderSendRecord"
          border
          show-summary
          style="width: 100%"
        >
          <el-table-column
            prop="createTime"
            label="发货时间"
            sortable
            :formatter="timestampToTime"
          >
          </el-table-column>
          <el-table-column prop="name" label="发货仓库"> </el-table-column>
          <el-table-column prop="sendCount" label="发货数量"> </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import XLSX from "xlsx";

export default {
  data() {
    return {
      drawer: false,
      orderSendRecord: [],
      // 请求数据
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      orderStateList: [
        { key: "1", display_name: "已下单" },
        { key: "2", display_name: "已设置运费" },
        { key: "3", display_name: "已签订合同" },
        { key: "4", display_name: "已支付" },
        { key: "5", display_name: "已发货" },
        { key: "6", display_name: "已完成" },
        { key: "7", display_name: "已取消" },
      ],
      sendTypeOptions: [
        {
          value: '1',
          label: "配送上门",
        },
        {
          value: '2',
          label: "自提",
        },
      ],
      warehouseList: [],
      queryRoleInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      productList: [],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      orderTotalPrice: "",
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {},
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      editDialogVisibleMoney: false,
      // 修改用户信息
      editForm: {
        productId: "",
        orderState: "",
        distributionType: "",
        warehouseId: "",
        sendCount: "",
      },
      editFormMoney: {
        carriage1: "",
        orderNaturalId: "",
        orderState: "",
        orderPrice: "",
      },
      deFlag: "",
      // 验证规则
      addFormRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 5, max: 15, message: "长度在 5 到 8 个字符", trigger: "blur" },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 8, message: "长度在 6 到 8 个字符", trigger: "blur" },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "blur" },
        ],
        sendCount: [
          { required: true, message: "请输入发货数量", trigger: "blur" },
        ],
      },
      editFormMoneyRules: {
        orderPrice: [
          { required: true, message: "请确认订单价格", trigger: "blur" },
        ],
        orderProductNum: [
          { required: true, message: "请确认订单价格", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    timestampToTime(row) {
      let date = new Date(row.createTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    async getUserList(param) {
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryNaturalOrder",
        this.queryInfo
      );
      for (let index = 0; index < res.resultBody.data.list.length; index++) {
        var address = res.resultBody.data.list[index].orderAddress
        if (null != address && address.length > 0) {
          var json = JSON.parse(res.resultBody.data.list[index].orderAddress);
          res.resultBody.data.list[index].orderAddress =
            json.province +
            " " +
            json.city +
            " " +
            json.county +
            " " +
            json.detail +
            " " +
            json.name +
            " " +
            json.phone;
        }
      }
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
      this.orderTotalPrice = res.resultBody.orderTotalPrice;
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 审核按钮
    async orderStateAudit(data, orderState) {
      if (5 == orderState) {
        //审核通过
        // 弹框
        const confirmResult = await this.$confirm(
          "此操作给客服订单发货, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        // 成功删除为confirm 取消为 cancel
        if (confirmResult != "confirm") {
          return this.$message.info("已取消审核");
        }
        var params = {
          sendScore: data.sendScore,
          userId: data.userId,
          orderState: orderState,
          orderNaturalId: data.orderNaturalId,
        };
        const { data: res } = await this.$http.post(
          "/chemical/natural/updateNaturalOrder",
          params
        );
        if (res.resultCode != "200") {
          return this.$message.error("操作失败！！！");
        }
        this.$message.success("操作成功！！！");
      }

      if (6 == orderState) {
        const confirmResult = await this.$confirm(
          "订单已完成?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        // 成功删除为confirm 取消为 cancel
        if (confirmResult != "confirm") {
          return '';
        }
        var params2 = {
          userId: data.userId,
          orderState: orderState,
          orderNaturalId: data.orderNaturalId,
        };
        const { data: res } = await this.$http.post(
          "/chemical/natural/updateNaturalOrder",
          params2
        );
        if (res.resultCode != "200") {
          return this.$message.error("操作失败！！！");
        }
        this.$message.success("操作成功！！！");
      }

      if (7 == orderState) {
        //审核不通过
        // 弹框
        const confirmResult = await this.$confirm(
          "此操作取消订单,是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        // 成功删除为confirm 取消为 cancel
        if (confirmResult != "confirm") {
          return this.$message.info("已取消审核");
        }
        var params1 = {
          userId: data.userId,
          orderState: orderState,
          orderNaturalId: data.orderNaturalId,
        };
        const { data: res } = await this.$http.post(
          "/chemical/natural/updateNaturalOrder",
          params1
        );
        if (res.resultCode != "200") {
          return this.$message.error("操作失败！！！");
        }
        this.$message.success("操作成功！！！");
      }

      this.getUserList();
    },
    // 监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },

    // 展示修改框
    async showEditDialog(data) {
      if (data["distributionType"] == 1) {
        data["warehouseId"] = "";
        data["sendCount"] = "";
        this.getWarehouseList(this.editForm.productId, this.editForm.skuId);
      }else {
        this.warehouseList = [
          {
            'warehouseId': data.warehouseId,
            'name': data.name
          }
        ];
      }

      this.editForm = data;
      // this.getWarehouseList(this.editForm.productId, this.editForm.skuId);
      this.editDialogVisible = true;
    },
    // 展示修改框
    async showEditDialogMoney(data) {
      this.editFormMoney = Object.assign({}, data);
      // this.editFormMoney['carriage1'] = this.editFormMoney['carriage1'] | '0'
      
      this.getWarehouseList(
        this.editFormMoney.productId,
        this.editFormMoney.skuId
      );

      if (this.editFormMoney.distributionType == 2) {
        var flag = false
        for (const element in this.warehouseList) {
          if (element['warehouseId'] == this.editFormMoney.warehouseId) {
            flag = true
            break
          }
        }
        if (!flag) {
          var obj = {
          'name': this.editFormMoney.name,
          'warehouseId': this.editFormMoney.warehouseId
        }
        this.warehouseList.push(obj)
        }
      }

      this.editDialogVisibleMoney = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.editFormMoney = {};
      this.$refs.editFormRef.resetFields();
    },
    updateNaturalOrderCarriage() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;

        // 发起请求
        var data = {
          orderNaturalId: this.editFormMoney.orderNaturalId,
          orderState: "4",
          carriage: this.editFormMoney.carriage1 | 0,
          orderPrice: this.editFormMoney.orderPrice,
          orderProductNum: this.editFormMoney.orderProductNum,
          distributionType: this.editFormMoney.distributionType
        };

        if (this.editFormMoney.distributionType === '1') {
          // 配送
          // 检测运费
          if (this.editFormMoney['carriage1'] == null) {
            this.$message.error("请填写运费");
            return
          }
          data['carriage'] = this.editFormMoney.carriage1
          data['warehouseId'] = ''
          data['name'] = ''
        }else if (this.editFormMoney.distributionType === '2') {
          // 自提
          data['carriage1'] = 0
          data['orderAddress'] = ''
          // 检测仓库选择
          if (this.editFormMoney['warehouseId'] == null) {
            this.$message.error("请选择自提仓库");
            return
          }
          data['warehouseId'] = this.editFormMoney['warehouseId']
          this.warehouseList.forEach(element => {
            if (element['warehouseId'] == data['warehouseId']) {
              data['name'] = element['name']
            }
          });
          for (const element in this.warehouseList) {
            if (element['warehouseId'] == data['warehouseId']) {
              data['name'] = element['name']
              break
            }
          }
        }

        const { data: res } = await this.$http.post(
          "/chemical/natural/updateNaturalOrderCarriage",
          data
        );
        if (res.resultCode != "200") return this.$message.error(res.resultMsg);
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisibleMoney = false;
        this.getUserList();
      });
    },
    async clickSeeRecord(record) {
      const { orderNum } = record;
      var data = {
        orderNum: orderNum,
      };
      const { data: res } = await this.$http.post(
        "/chemical/natural/gerOrderSendRecord",
        data
      );
      this.orderSendRecord = res.resultBody.list;
      this.drawer = true;
    },
    // 确认修改
    editOrderInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        var data = {
          productId: this.editForm.productId,
          skuId: this.editForm.skuId,
          orderState: "5",
          distributionType: this.editForm.distributionType,
          warehouseId: this.editForm.warehouseId,
          orderNaturalId: this.editForm.orderNaturalId,
          orderProductNum: this.editForm.orderProductNum,
          sendCount: this.editForm.sendCount,
        };
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/natural/updateNaturalOrder",
          data
        );
        if (res.resultCode != "200") return this.$message.error(res.resultMsg);
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    async getWarehouseList(productId, skuId) {
      // 调用post请求
      var data = {
        productNaturalId: productId,
        skuId: skuId,
        pageNo: "1",
        pageSize: "1000",
      };
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryWarehouse",
        data
      );
      this.warehouseList = res.resultBody.data.list; // 将返回数据赋值
    },
    exportFile() {
      var sheetData = [];
      this.userlist.forEach(order => {
        var dict = {
          '订单号': order.orderNum,
          '下单时间': this.timestampToTime(order),
          '下单用户': (order.companyName || '') + (order.phone || ''),
          '商品名称': order.productName + " " + order.attr,
          '订单数量': order.orderProductNum,
          '货物总金额（元）': '￥' + order.orderPrice,
          '配送方式': order.distributionType == 1 ? '配送上门' : '到库自提',
          '地址/自提仓库': order.distributionType == 1 ? order.orderAddress : order.name
        }
        sheetData.push(dict)
      });
      var date = new Date;
      let fileName = "订单列表" + this.timestampToTime({createTime: date});
      // 将由对象组成的数组转化成sheet
      const sheet = XLSX.utils.json_to_sheet(sheetData);
      // 百分数和数字更改为数字类型
      Object.keys(sheet).forEach((key) => {
        if (sheet[key].v) {
          const val = sheet[key].v;
          if (!isNaN(val)) {
            sheet[key].t = "n";
          }
          // if (val.lastIndexOf("%") === val.length - 1) {
          //   sheet[key].t = "n";
          //   sheet[key].z = "0.00%";
          //   sheet[key].v = Number(val.substring(0, val.length - 1)) / 100;
          // }
        }
      });
      // 创建虚拟的workbook
      const wb = XLSX.utils.book_new();
      // 把sheet添加到workbook中
      XLSX.utils.book_append_sheet(wb, sheet, fileName);
      const workbookBlob = this.workbook2blob(wb);
      this.openDownload(workbookBlob, `${fileName}.xls`);
    },

    openDownload(blob, fileName) {
      if (typeof blob === "object" && blob instanceof Blob) {
        blob = URL.createObjectURL(blob); // 创建blob地址
      }
      const aLink = document.createElement("a");
      aLink.href = blob;
      // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
      aLink.download = fileName || "";
      let event;
      if (window.MouseEvent) event = new MouseEvent("click");
      //   移动端
      else {
        event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
      }
      aLink.dispatchEvent(event);
    },
    workbook2blob(workbook) {
      // 生成excel的配置项
      const wopts = {
        // 要生成的文件类型
        bookType: "xlsx",
        // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        bookSST: false,
        type: "binary",
      };
      const wbout = XLSX.write(workbook, wopts);
      // 将字符串转ArrayBuffer
      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      return blob;
    },
  },
};
</script>
<style lang="less" scoped>
.card-header {
  text-align: center;
}

.card-body {
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
}

.card-body-left {
  width: 50%;
}

.card-body-right {
  width: 50%;
  color: rgb(64, 174, 247);
  // float: left;
}
</style>
