<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加角色</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="角色名称" prop="roleName"></el-table-column>
        <el-table-column label="角色描述" prop="description"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="修改时间" prop="updateTime"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
            ></el-button>
            <!-- 删除 -->
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="deleteUser(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 创建新用户对话框 -->
    <el-dialog
      title="添加角色"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="70px"
      >
        <!-- 用户名 -->
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="addForm.roleName"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="角色描述" prop="description">
          <el-input v-model="addForm.description"></el-input>
        </el-form-item>
        <el-tree
          :data="data"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          highlight-current
          :props="defaultProps"
        >
        </el-tree>
        <!-- <div class="buttons">
          <el-button @click="resetChecked">清空</el-button>
        </div> -->
      </el-form>
      <!-- 内容底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改角色"
      :visible.sync="editDialogVisible"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="70px"
      >
        <!-- 用户名 -->
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="editForm.roleName" disabled></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="角色描述" prop="description">
          <el-input v-model="editForm.description"></el-input>
        </el-form-item>
        <!-- 菜单 -->
        <el-tree
          :data="data"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          highlight-current
          :props="defaultProps"
        >
        </el-tree>
        <!-- <div class="buttons">
          <el-button @click="resetChecked">清空</el-button>
          <el-button @click="setCheckedKeys">通过 key 设置</el-button>
        </div> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 请求数据
      queryInfo: {
        query: "",
        realName: "",
        pageNo: 1,
        pageSize: 10,
      },
      queryRoleInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      list: [], // 角色列表
      rolelist: [], // 角色列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {
        roleName: "",
        description: "",
        roleMenu: "",
      },
      parent: [],
      arr: [],
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        id: "",
        roleName: "",
        description: "",
        roleMenu: "",
      },
      // 验证规则
      addFormRules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入角色描述", trigger: "blur" },
        ],
        defaultProps:[
          { required: true, message: "请选择菜单", trigger: "blur" },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入角色描述", trigger: "blur" },
        ],
        defaultProps:[
          { required: true, message: "请选择菜单", trigger: "blur" },
        ],
      },
      data: [],
      defaultProps: {
        children: "sysPermissions",
        label: "name",
      },
    };
  },
  created() {
    this.getList();
    this.getMenuList();
  },
  methods: {
    async getList() {
      // 调用post请求
      var qs = require("querystring");
      const { data: res } = await this.$http.post(
        "/role/list",
        qs.stringify(this.queryInfo)
      );
      this.list = res.resultBody.data.list;
      this.total = res.resultBody.data.total; // 总个数
    },
    async getMenuList() {
      // 调用post请求
      const { data: res } = await this.$http.post("/role/listRoleMenu");
      this.data = res.resultBody.data; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
      for (var i = 0; i < this.data.length; i++) {
        this.parent.push(this.data[i].id);
      }
      console.log("hahaha" + this.parent);
    },
    async getRoleList() {
      // 调用post请求
      var qs = require("querystring");
      const { data: res } = await this.$http.post(
        "/role/list",
        qs.stringify(this.queryRoleInfo)
      );
      this.rolelist = res.resultBody.data.list; // 将返回数据赋值
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getList(); // 数据发生改变重新申请数据
    },
    //修改用户状态
    async userStateChanged() {
      var qs = require("querystring");
      const { data: res } = await this.$http.post(
        "/user/update",
        qs.stringify(this.editForm)
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
    },
    // 监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },
    // 添加用户
    addUser() {
      this.$refs.addFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        var qs = require("querystring");
        let parentArr = this.$refs.tree.getHalfCheckedKeys(); //获取半选中状态的id
        let childArr = this.$refs.tree.getCheckedKeys(); //获取全选中的id
        this.addForm.roleMenu = parentArr.concat(childArr);
        const { data: res } = await this.$http.post(
          "/role/add",
          qs.stringify(this.addForm)
        );
        if (res.resultCode != "200") {
          return this.$message.error("操作失败！！！");
        }
        this.$message.success("操作成功！！！");
        //隐藏
        this.addDialogVisible = false;
        this.getList();
      });
    },
    // 展示修改框
    async showEditDialog(data) {
      this.checkStrictly = true;
      this.editForm = data;
      this.editDialogVisible = true;
      this.arr = data.roleMenu.split(",");
      var c = [];
      for (var i = 0; i < this.arr.length; i++) {
        if (this.parent.indexOf(this.arr[i]) === -1) {
          c.push(this.arr[i]);
        }
      }
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(c);
        this.checkStrictly = false;
      });
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        var qs = require("querystring");
        let parentArr = this.$refs.tree.getHalfCheckedKeys(); //获取半选中状态的id
        let childArr = this.$refs.tree.getCheckedKeys(); //获取全选中的id
        this.editForm.roleMenu = parentArr.concat(childArr);
        const { data: res } = await this.$http.post(
          "/role/update",
          qs.stringify(this.editForm)
        );
        console.log(res);
        if (res.resultCode != "200")
          return this.$message.error("操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getList();
      });
    },
    // 删除按钮
    async deleteUser(id) {
      // 弹框
      const confirmResult = await this.$confirm(
        "此操作将删除该角色, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 成功删除为confirm 取消为 cancel
      if (confirmResult != "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post("/role/delete?id=" + id);
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getList();
    },
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      console.log(this.$refs.tree.getCheckedKeys());
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([
        {
          id: 5,
          lable: "二级 2-1",
        },
        {
          id: 9,
          name: "三级 1-1-1",
        },
      ]);
    },
    setCheckedKeys() {
      alert(this.arr);
      this.$refs.tree.setCheckedKeys(this.arr);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    getArr() {
      console.log("菜单：" + window.sessionStorage.getItem("sysPermissions"));
    },
  },
};
</script>
<style lang="less" scoped>
</style>