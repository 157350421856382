<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>留言板管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <template slot="header">
        <el-row>
          <el-col :span="8">
            <div style="display:flex;flex-direction: row;align-items: center;">
              <p style="width:100px">状态</p>
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                style="margin-left:15px"
                @click="getList()"
                >搜索</el-button
              >
            </div>
          </el-col>
        </el-row>
      </template>
      <!-- 用户列表 -->
      <el-table :data="list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="客户联系方式" prop="phone"></el-table-column>
        <el-table-column label="时间" prop="createTime" :formatter="timestampToTime"></el-table-column>
        <el-table-column label="内容" prop="msg"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 删除 -->
            <el-popconfirm title="确认已处理吗" @confirm="handleMsg(scope)">
              <el-button
                type="primary"
                slot="reference"
                v-if="scope.row.statue == '0'"
                >处理</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      value: "0",
      options: [
        {
          value: "0",
          label: "未处理",
        },
        {
          value: "1",
          label: "已处理",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      // 调用post请求
      let data = await this.$http.post("/message/query", {
        statue: this.value,
      });
      this.list = data.data.resultBody.data;
    },
    async handleMsg(scope) {
      await this.$http.post("/message/update", {
        id: scope.row.id,
        statue: "1",
      });
      this.getList();
    },
    timestampToTime(row) {
      let date = new Date(row.createTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    }
  },
};
</script>
<style lang="less" scoped></style>
