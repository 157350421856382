<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>业务管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品规格</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="25">
        <el-col :span="24">
          <el-button size="small" type="primary" @click="addAttrClick"
            >添加商品规格</el-button
          >
          <!-- <el-button size="small" type="danger" @click="muliteDeleteBtnClick">批量删除</el-button> -->
        </el-col>
        <el-col :span="24">
          <el-table
            v-loading="loading"
            :data="list"
            row-key="id"
            style="font-size: 12px;"
          >
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column label="规格名称" prop="name" min-width="150px" />
            <el-table-column label="商品规格" min-width="150px">
              <template #default="scope">
                {{ getTableAttrName(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column label="商品属性" min-width="150px">
              <template #default="scope">
                <div v-for="item in getTableAttrValue(scope.row)" :key="item">
                  {{ item }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="editAttr(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="color: #f56c6c"
                  size="small"
                  @click="deleteAttr(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            :visible.sync="visibleDialog"
            width="80%"
            title="属性规格"
            :destroy-on-close="true"
          >
            <createAttribute
              :add-form="defaultAddForm"
              :is-add="isAdd"
              @close="visibleDialog = false"
              @addAttrSuccess="addAttrSuccess"
            />
          </el-dialog>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import createAttribute from "./createAttribute/index.vue";
export default {
  components: { createAttribute },
  data() {
    return {
      loading: false,
      list: [],
      visibleDialog: false,
      ruleName: "",
      multipleSelection: [],
      defaultAddForm: {
        name: "",
        item: [],
      },
      isAdd: false,
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.$http
        .get("/chemical/attr/get")
        .then((res) => {
          this.loading = false;
          this.list = res.data.resultBody.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      //   getAttribute()
      //     .then(res => {
      //       this.list = res.data.obj
      //       this.loading = false
      //     })
      //     .catch(() => {
      //       this.loading = false
      //     })
    },
    getTableAttrName(row) {
      var values = [];
      row.item.forEach((element) => {
        values.push(element.value);
      });
      return values.join(" ");
    },
    getTableAttrValue(row) {
      var result = [];

      row.item.forEach((element) => {
        var values = [];
        element.item.forEach((attr) => {
          values.push(attr);
        });
        result.push(values.join(" "));
      });
      return result;
    },
    addAttrClick() {
      this.defaultAddForm = {
        name: "",
        item: [],
      };
      this.isAdd = true;
      this.visibleDialog = true;
    },
    editAttr(row) {
      this.isAdd = false;
      this.defaultAddForm = {
        name: row.name,
        item: row.item,
        id: row.id,
      };
      console.log('====================================');
      console.log(this.defaultAddForm);
      console.log('====================================');
      this.visibleDialog = true;
    },
    addAttrSuccess() {
      this.visibleDialog = false;
      this.getTableData();
    },
    deleteAttr(row) {
      this.deleteAction([row]);
    },
    deleteAction(item) {
      const loading = this.$loading({
        lock: true,
        text: "删除中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$http
        .post("/chemical/attr/delete", item[0])
        .then(() => {
          loading.close();
          this.$message.success("删除成功");
          this.getTableData();
        })
        .catch(() => {
          loading.close();
        });
    },
  },
};
</script>

<style></style>
