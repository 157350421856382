<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="1.5">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加轮播图</el-button
          >
        </el-col>
        <el-col :span="4" >
          <!-- 搜索添加 -->
          <el-select style="width:100%"
            v-model="queryInfo.comeFrom"
            clearable
            placeholder="请选择轮播图类型"
            @clear="getUserList(1)"
          >
            <el-option
              v-for="item in comeFromlist"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-col>
        <el-col :span="1.5">
          <el-button icon="el-icon-search" @click="getUserList(1)"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="1.5">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"
            >刷新</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="轮播图" prop="url">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.url">
            </viewer>
            <!-- 页面table显示的video标签 -->
            <video
              v-if="scope.row.type == 2"
              ref="startvideo"
              controls="controls"
              :src="scope.row.url"
              style="width: 15%; height: 15%"
            ></video>
          </template>
        </el-table-column>
        <el-table-column label="跳转商品" prop="productName"></el-table-column>
        <el-table-column label="图片简介" prop="content"></el-table-column>
        <el-table-column label="轮播图类型" prop="comeFrom">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.comeFrom == 1">小程序</el-tabs>
            <el-tabs v-if="scope.row.comeFrom == 2">web</el-tabs>
          </template>
        </el-table-column>
        <!-- <el-table-column label="文件类型" prop="type">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.type == 1">图片</el-tabs>
            <el-tabs v-if="scope.row.type == 2">视频</el-tabs>
          </template>
        </el-table-column> -->
        
        <el-table-column label="操作"  fixed="right" width="180px">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="showEditDialog(scope.row)"
              >修改</el-button
            >
            <el-button
              type="success"
              size="mini"
              icon="el-icon-delete"
              @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 创建新用户对话框 -->
    <el-dialog
      title="添加轮播图"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="选择商品" prop="productNaturalId">
          <el-select
            :data="productList"
            placeholder="请选择商品"
            clearable
            v-model="addForm.productNaturalId"
          >
            <el-option
              v-for="item in productList"
              :key="item.productNaturalId"
              :label="item.productName"
              :value="item.productNaturalId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 用户名 -->
        <el-form-item label="图片简介" prop="content">
          <el-input
            v-model="addForm.content"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入图片简介"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="轮播图类型" prop="comeFrom">
          <el-select
            placeholder="请选择轮播类型"
            v-model="addForm.comeFrom"
            clearable
          >
            <el-option
              v-for="item in comeFromlist"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="文件类型" prop="type">
          <el-select
            placeholder="请选择文件类型"
            v-model="addForm.type"
            clearable
          >
            <el-option
              v-for="item in typelist"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="上传文件" prop="url">
          <el-upload accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
            action
            :limit="1"
            v-model="addForm.url"
            :file-list="formFileList"
            :http-request="handleUploadForm"
            :on-exceed="formHandleExceed"
            :on-preview="handlePreview"
            ><el-button type="primary">上传文件</el-button></el-upload
          >
          <p>PC端图片尺寸要求 1920*510</p>
            <p>小程序端尺寸要求是375*172</p>
        </el-form-item>
      </el-form>
      <!-- 内容底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改轮播图"
      :visible.sync="editDialogVisible"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="选择商品" prop="productNaturalId">
          <el-select
            :data="productList"
            placeholder="请选择商品"
            clearable
            v-model="editForm.productNaturalId"
          >
            <el-option
              v-for="item in productList"
              :key="item.productNaturalId"
              :label="item.productName"
              :value="item.productNaturalId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 用户名 -->
        <el-form-item label="图片简介" prop="content">
          <el-input
            v-model="editForm.content"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入图片简介"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="轮播图类型" prop="comeFrom">
          <el-select
            placeholder="请选择轮播类型"
            v-model="editForm.comeFrom"
            clearable
          >
            <el-option
              v-for="item in comeFromlist"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="文件类型" prop="type">
          <el-select
            placeholder="请选择文件类型"
            v-model="editForm.type"
            clearable
          >
            <el-option
              v-for="item in typelist"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="上传文件" prop="url">
          <el-upload accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
            action
            :limit="1"
            v-model="editForm.url"
            :file-list="formFileList"
            :http-request="handleUploadFormUpdate"
            :on-exceed="formHandleExceed"
            :on-preview="handlePreview"
            ><el-button type="primary">上传文件</el-button></el-upload
          >
          <p>PC端图片尺寸要求 1920*510</p>
            <p>小程序端尺寸要求是375*172</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formMaxSize: 10, // 上传文件大小
      formFileList: [], // 显示上传文件
      uploadFormFileList: [], // 确定上传文件
      dialogVisible: false,
      dialogInfo: {},
      // 请求数据
      queryInfo: {
        info: "",
        pageNo: 1,
        pageSize: 10,
      },
      queryProductInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      dialogImageUrl: "",
      comeFromlist: [
        { key: "1", display_name: "小程序" },
        { key: "2", display_name: "web" },
      ],
      typelist: [
        { key: "1", display_name: "图片" },
        { key: "2", display_name: "视频" },
      ],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {
        url: "",
        productNaturalId:"",
        content:"",
        comeFrom:"",
        type:"1",
      },
      productList: [],
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        url: "",
        productNaturalId:"",
        content:"",
        comeFrom:"",
        type:"1",
      },

      delFlag: "",
      // 验证规则
      addFormRules: {
        productNaturalId: [
          { required: true, message: "请选择商品", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入图片简介", trigger: "blur" },
        ],
        comeFrom: [
          { required: true, message: "请选择轮播图类型", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择文件类型", trigger: "blur" },
        ],
        
      },
      // 修改用户表单验证规则
      editFormRules: {
        productNaturalId: [
          { required: true, message: "请选择商品", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入图片简介", trigger: "blur" },
        ],
        comeFrom: [
          { required: true, message: "请选择轮播图类型", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择文件类型", trigger: "blur" },
        ],
       
      },
    };
  },
  created() {
    this.getUserList();
    this.getProductList();
  },
  methods: {
    handleClick(info) {
      console.log(info);
      this.dialogVisible = true;
      this.dialogInfo = info;
    },
    dialogVisibles(v) {
      this.dialogVisible = v;
    },
    onRefresList() {
      // this.queryInfo.pageNo = '1';
      this.getUserList();
    },
    handlePreview(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    startvideo() {
      if (this.$refs.startvideo.paused) {
        this.$refs.startvideo.play();
      } else {
        this.$refs.startvideo.play();
      }
    },
    startPic() {},
    // 允许上传文件个数验证
    formHandleExceed(files, formFileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + formFileList.length
        } 个文件`
      );
    },
    // 上传文件
    async handleUploadForm(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "1"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.formFileList[0] = res.resultBody.data;
        this.addForm.url = thiz.formFileList[0];
        thiz.uploadFormFileList = [];
      } else {
        thiz.formFileList = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadFormUpdate(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "1"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.formFileList[0] = res.resultBody.data;
        this.editForm.url = thiz.formFileList[0];
        thiz.uploadFormFileList = [];
      } else {
        thiz.formFileList1 = [];
        thiz.uploadFormFileList1 = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    timestampToTime(row) {
      let date = new Date(row.createTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/shufflingFigureList",
        this.queryInfo
      );
      console.log("结果：" + res);
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
    },
    async getProductList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryNaturalProduct",
        this.queryProductInfo
      );
      console.log("结果：" + res);
      this.productList = res.resultBody.data.list; // 将返回数据赋值
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields(); // 重置表单项
      this.formFileList = [];
    },
    // 添加用户
    addUser() {
      this.$refs.addFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/basic/shufflingFigureInsert",
          this.addForm
        );
        if (res.resultCode != "200") {
          //userinfo.state = !userinfo.state;
          return this.$message.error("操作失败！！！");
        }
        this.$message.success("操作成功！！！");
        //隐藏
        this.addDialogVisible = false;
        this.getUserList();
      });
    },
    // 展示修改框
    async showEditDialog(data) {
      this.editForm = Object.assign(data);
      // this.formFileList1 =  [{name: data.url.split("/")[data.url.split("/").length-1]}];
      this.editDialogVisible = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/basic/shufflingFigureUpdate",
          this.editForm
        );
        console.log(res);
        if (res.resultCode != "200")
          return this.$message.error("操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 删除按钮
    async deleteUser(shufflingId){
      // 弹框
      const confirmResult = await this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
      // 成功删除为confirm 取消为 cancel
      if(confirmResult!='confirm'){
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post(
        "/chemical/basic/shufflingFigureDelete",
        shufflingId
      );
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },
  },
};
</script>
<style lang="less" scoped>
</style>