<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="1.5">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加仓库商品</el-button
          >
        </el-col>
        <el-col :span="4">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入仓库名称"
            v-model="queryInfo.warehouseName"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入商品名称"
            v-model="queryInfo.productName"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <el-col :span="0.5">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="仓库名称"
          prop="warehouseName"
        ></el-table-column>
        <el-table-column label="商品名称" prop="productName"></el-table-column>
        <el-table-column label="规格名称" prop="attr"></el-table-column>
        <el-table-column
          label="总库存数（吨）"
          prop="totalNum"
        ></el-table-column>
        <el-table-column
          label="使用库存数（吨）"
          prop="userNum"
        ></el-table-column>
        <el-table-column label="剩余库存数（吨）" prop="remainNum">
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
              >增加商品库存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 创建新用户对话框 -->
    <el-dialog
      title="添加仓库商品"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <!-- 用户名 -->
        <el-form-item label="选择仓库" prop="warehouseId">
          <el-select
            :data="warehouseList"
            placeholder="请选择仓库"
            clearable
            v-model="addForm.warehouseId"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.warehouseId"
              :label="item.name"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品" prop="productId">
          <el-cascader
            style="width:100%"
            v-model="addForm.selectValue"
            :options="formatForCas(productList)"
            :props="{ expandTrigger: 'hover' }"
            @change="handleProductChange"
          ></el-cascader>
          <!-- <el-select
            :data="productList"
            placeholder="请选择商品"
            clearable
            v-model="addForm.productId"
          >
            <el-option
              v-for="item in productList"
              :key="item.productId"
              :label="item.productName"
              :value="item.productNaturalId"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
      </el-form>
      <!-- 内容底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="添加商品库存"
      :visible.sync="editDialogVisible"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="选择仓库" prop="warehouseId">
          <el-select
            :data="warehouseList"
            placeholder="仓库"
            clearable
            :disabled="true"
            v-model="editForm.warehouseId"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.warehouseId"
              :label="item.name"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择商品" prop="productId">
          <el-cascader
            v-model="editForm.selectValue"
            :options="formatForCas(productList)"
            :props="{ expandTrigger: 'hover' }"
            :disabled='true'
          ></el-cascader>
          <!-- <el-select
            :data="productList"
            placeholder="商品"
            clearable
            :disabled="true"
            v-model="editForm.productId"
          >
            <el-option
              v-for="item in productList"
              :key="item.productId"
              :label="item.productName"
              :value="item.productNaturalId"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="添加数量" prop="insertNum">
          <el-input v-model="editForm.insertNum"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 请求数据
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      queryRoleInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      queryProductInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      queryWarehouseInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      productList: [],
      warehouseList: [],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {
        warehouseId: "",
        productId: "",
        skuId: "",
        selectValue: []
      },
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        userNum: "0",
        warehouseId: "",
        productId: "",
        selectValue: []
      },

      deFlag: "",
      // 验证规则
      addFormRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "blur" },
        ],
        productId: [{ required: true, message: "请选择商品", trigger: "blur" }],
      },
      // 修改用户表单验证规则
      editFormRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "blur" },
        ],
        productId: [{ required: true, message: "请选择商品", trigger: "blur" }],
        insertNum: [{ required: true, message: "请选择商品", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getUserList();
    this.getProductList();
    this.getWarehouseList();
  },
  methods: {
    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/warehouseProductList",
        this.queryInfo
      );
      console.log("结果：" + res);
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
    },
    async getRoleList() {
      // 调用post请求
      var qs = require("querystring");
      const { data: res } = await this.$http.post(
        "/role/list",
        qs.stringify(this.queryRoleInfo)
      );
      console.log("结果：" + res);
      this.rolelist = res.resultBody.data.list; // 将返回数据赋值
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    //修改用户状态
    async userStateChanged(data, b) {
      console.log(b);
      var qs = require("querystring");
      const { data: res } = await this.$http.post(
        "/user/updateUserState",
        qs.stringify(b)
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
    },
    // 监听添加用户
    addDialogClosed() {
      this.addForm = {
        warehouseId: "",
        productId: "",
        skuId: "",
        selectValue: []
      }
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },
    // 添加用户
    addUser() {
      this.$refs.addFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/basic/warehouseProductInsert",
          this.addForm
        );
        if (res.resultCode != "200") {
          return this.$message(res.resultMsg);
        }
        this.$message.success("操作成功！！！");
        //隐藏
        this.addDialogVisible = false;
        this.getUserList();
      });
    },
    // 展示修改框
    async showEditDialog(data) {
      this.editForm = data;
      console.log('====================================');
      console.log(this.editForm);
      console.log('====================================');
      this.editForm.selectValue = [data.productId, data.skuId]
      this.editDialogVisible = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/basic/productWarehouseInsert",
          this.editForm
        );
        console.log(res);
        if (res.resultCode != "200")
          return this.$message.error("操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 删除按钮
    async deleteUser(id) {
      // 弹框
      const confirmResult = await this.$confirm(
        "此操作将删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 成功删除为confirm 取消为 cancel
      if (confirmResult != "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post("/user/delete?id=" + id);
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },
    async getProductList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryNaturalProduct",
        this.queryProductInfo
      );
      console.log("结果：" + res);
      this.productList = res.resultBody.data.list; // 将返回数据赋值
    },
    async getWarehouseList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/warehouseList",
        this.queryWarehouseInfo
      );
      console.log("结果：" + res);
      this.warehouseList = res.resultBody.data.list; // 将返回数据赋值
    },
    handleProductChange() {
      this.addForm['productId']= this.addForm.selectValue[0]
      this.addForm['skuId']= this.addForm.selectValue[1]
    },
    formatForCas(list) {
      var result = []
      list.forEach(product => {
        var obj = {}
        obj['value'] = product['productNaturalId']
        obj['label'] = product['productName']
        obj['children'] = []
        product.sku.forEach(sku => {
          var skuObj = {}
          skuObj['value'] = sku['id']
          skuObj['label'] = sku['sku'] || product['productName']
          obj['children'].push(skuObj)
        });
        result.push(obj)
      });
      return result
    }
  },
};
</script>
<style lang="less" scoped></style>
