<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础管理</el-breadcrumb-item>
      <el-breadcrumb-item>拼团商品审核</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="商品名称" prop="productName">
          <template slot-scope="scoped">
            {{ scoped.row.productName + ' ' + scoped.row.attr }}
          </template>
        </el-table-column>
        <el-table-column label="商品封面图片" prop="productMainImage">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.productMainImage" />
            </viewer>
          </template>
        </el-table-column>
        <el-table-column label="商品详情图片" prop="productContent">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.productContent" />
            </viewer>
          </template>
        </el-table-column>
        <el-table-column
          label="预交价格"
          prop="acceptingThePrice"
        ></el-table-column>
        <el-table-column label="单价/吨" prop="productPrice"></el-table-column>
        <!-- <el-table-column label="审核状态" prop="priceAudit">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.priceAudit == 1">未审核</el-tabs>
            <el-tabs v-if="scope.row.priceAudit == 2">审核通过</el-tabs>
            <el-tabs v-if="scope.row.priceAudit == 3">审核未通过</el-tabs>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 创建新用户对话框 -->
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改拼团"
      :visible.sync="editDialogVisible"
      v-if="editDialogVisible"
      width="600px"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <!-- <el-divider style="color: red">商品销售属性，必填</el-divider> -->
        <!-- 用户名 -->
        <!-- <el-divider style="color: red">商品销售属性，必填</el-divider> -->
        <div class="a">
          <el-form-item class="b" label="审核结果" prop="priceAudit">
            <el-select
              placeholder="请选择类型"
              v-model="editForm.priceAudit"
              clearable
            >
              <el-option
                v-for="item in isProductShelvesList"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      editorOption: {
        placeholder: "请输入正文",
      },
      formMaxSize: 10, // 上传文件大小
      uploadFormFileList: [], // 确定上传文件
      // 请求数据
      queryInfo: {
        auditPrice: "1",
        pageNo: 1,
        pageSize: 10,
      },
      queryTypeInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      queryWarehouseInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      isProductShelvesList: [
        { key: "2", display_name: "审核通过" },
        { key: "3", display_name: "审核不通过" },
      ],
      isHotProductList: [
        { key: "1", display_name: "是" },
        { key: "0", display_name: "否" },
      ],
      fileTypeList: [
        { key: "1", display_name: "图片" },
        { key: "2", display_name: "视频" },
      ],
      typeList: [],
      warehouseList: [],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {
        
      },

      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        acceptingThePrice: "",
        productPrice: "",
      },
      // 验证规则
      addFormRules: {
         priceAudit: [
          { required: true, message: "请选择审核结果", trigger: "blur" },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        priceAudit: [
          { required: true, message: "请选择审核结果", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
    this.getTypeList();
  },
  methods: {
    handleSelectChange() {
      this.$forceUpdate();
    },
    handleDeleteAllDetails() {
      this.addForm.productPriceDataList = undefined;
      console.log(
        "清空后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },
    //单选框选中数据
    handleDetailSelectionChange(selection) {
      if (selection.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(selection.pop());
      } else {
        this.checkedDetail = selection;
      }
    },
    handleAddDetails() {
      if (this.addForm.productPriceDataList == undefined) {
        this.addForm.productPriceDataList = new Array();
      }
      let obj = {};
      obj.productSpecifications = "";
      obj.productSpecificationsMin = "";
      obj.productSpecificationsMax = "";
      obj.productPrice = "";
      obj.productSendIntegral = "";
      this.addForm.productPriceDataList.push(obj);
      console.log(
        "增加后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    handleDeleteDetails() {
      if (this.checkedDetail.length == 0) {
        this.$alert("请先选择要删除的数据", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.addForm.productPriceDataList.splice(
          this.checkedDetail[0].xh - 1,
          1
        );
      }
      console.log(
        "删除后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    clearFields() {
      (this.addForm.content = ""),
        (this.addForm.groupTotal = ""),
        (this.addForm.minPurchaseQuantity = ""),
        (this.addForm.acceptingThePrice = ""),
        (this.addForm.groupEndTime = ""),
        (this.addForm.productPrice = ""),
        (this.addForm.productName = ""),
        (this.addForm.isProductShelves = ""),
        (this.addForm.fileType = ""),
        (this.addForm.productPhotoAlbums = []),
        (this.addForm.productPriceDataList = []),
        (this.addForm.formFileList1 = []), // 显示上传文件
        (this.addForm.formFileList2 = []); // 显示上传文件
    },
    handleDeleteAllDetailsUpdate() {
      this.editForm.productPriceDataList = undefined;
      console.log(
        "清空后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },
    handleAddDetailsUpdate() {
      if (this.editForm.productPriceDataList == undefined) {
        this.editForm.productPriceDataList = new Array();
      }
      let obj = {};
      obj.productSpecifications = "";
      obj.productSpecificationsMin = "";
      obj.productSpecificationsMax = "";
      obj.productPrice = "";
      obj.productSendIntegral = "";
      this.editForm.productPriceDataList.push(obj);
      console.log(
        "增加后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },
    handleDeleteDetailsUpdate() {
      if (this.checkedDetail.length == 0) {
        this.$alert("请先选择要删除的数据", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.editForm.productPriceDataList.splice(
          this.checkedDetail[0].xh - 1,
          1
        );
      }
      console.log(
        "删除后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },

    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      this.queryInfo.priceAudit = 1;
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/group/queryGroupProduct",
        this.queryInfo
      );
      console.log("结果：" + res);
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
    },
    timestampToTime(row) {
      let date = new Date(row.groupEndTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    //修改用户状态
    async userStateChanged(data, b) {
      const { data: res } = await this.$http.post(
        "/chemical/group/updateGroupProduct",
        b
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
    },
    // 监听添加用户
    addDialogClosed() {
      console.log("进来了:");
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },
    handlePreview(file) {
      console.log(file);
    },
    handleRemoveProductContent(file, formFileList1) {
      this.addForm.formFileList1 = formFileList1;
      console.log("移除之后formFileList1：" + this.addForm.formFileList1);
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductMainProduct(file, formFileList2) {
      this.addForm.formFileList2 = formFileList2;
      console.log("移除之后formFileList2：" + this.addForm.formFileList2);
    },
    handleRemove(file, productPhotoAlbums) {
      this.addForm.productPhotoAlbums = productPhotoAlbums;
      console.log(
        "移除之后productPhotoAlbums：" + this.addForm.productPhotoAlbums
      );
    },
    handleRemoveUpdate(file, productPhotoAlbums) {
      this.editForm.productPhotoAlbums = productPhotoAlbums;
      console.log(
        "移除之后productPhotoAlbums：" + this.editForm.productPhotoAlbums
      );
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductContentUpdate(file, formFileList1) {
      this.editForm.formFileList1 = formFileList1;
      console.log("移除之后formFileList1：" + formFileList1);
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductMainProductUpdate(file, formFileList2) {
      this.editForm.formFileList2 = formFileList2;
      console.log("移除之后formFileList2：" + this.editForm.formFileList2);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    startvideo() {
      if (this.$refs.startvideo.paused) {
        this.$refs.startvideo.play();
      } else {
        this.$refs.startvideo.play();
      }
    },
    startPic() {},
    // 允许上传文件个数验证
    formHandleExceed(files, formFileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + formFileList.length
        } 个文件`
      );
    },
    // 上传文件
    async handleUploadForm1(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.addForm.formFileList1 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productContent: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.addForm.formFileList1 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm2(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.addForm.formFileList2 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productMainImage: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.addForm.formFileList2 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm3(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        console.log("res.resultBody.data:" + res.resultBody.data);
        thiz.addForm.productPhotoAlbums.push({
          fileType: thiz.addForm.fileType,
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productFileUrl: res.resultBody.data,
          url: res.resultBody.data,
        });
        thiz.uploadFormFileList = [];
        console.log(
          "thiz.addForm" + JSON.stringify(thiz.addForm.productPhotoAlbums)
        );
      } else {
        thiz.addForm.productPhotoAlbums = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },

    // 上传文件
    async handleUploadForm4(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");

        thiz.editForm.formFileList1.push({
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productContent: res.resultBody.data,
          url: res.resultBody.data,
        });
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.formFileList1 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm5(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.editForm.formFileList2 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productMainImage: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.formFileList2 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm6(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        console.log("res.resultBody.data:" + res.resultBody.data);
        thiz.editForm.productPhotoAlbums.push({
          fileType: this.editForm.fileType,
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productFileUrl: res.resultBody.data,
        });
        console.log(
          "this.editForm" + JSON.stringify(this.editForm.productPhotoAlbums)
        );
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.productPhotoAlbums = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },

    // 添加用户
    addUser() {
      if (this.addForm.fileType == 2) {
        if (this.addForm.productPhotoAlbums.length > 1) {
          return this.$message.info("相册类型选择的是视频，只能上传一个文件");
        }
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        // 发起请求
        this.addForm.productContent = this.addForm.formFileList1[0].url;
        this.addForm.productMainImage = this.addForm.formFileList2[0].url;
        const { data: res } = await this.$http.post(
          "/chemical/group/addGroupProduct",
          this.addForm
        );
        if (res.resultCode != "200") {
          return this.$message(res.resultMsg);
        }
        this.$message.success("操作成功！！！");
        //隐藏
        this.addDialogVisible = false;
        this.getUserList();
      });
    },
    // 展示修改框
    showEditDialog(data) {
      this.editForm = data;
      this.editForm.priceAudit = "";
      this.editDialogVisible = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        var obj={};
        obj.priceAudit = this.editForm.priceAudit;
        obj.productGroupId = this.editForm.productGroupId;
        const { data: res } = await this.$http.post(
          "/chemical/group/auditGroupProduct",
         obj
        );
        console.log(res);
        if (res.resultCode != "200")
          return this.$message.error("操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 删除按钮
    async deleteEditDialog(productNaturalId) {
      // 弹框
      const confirmResult = await this.$confirm(
        "此操作将删除该商品, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 成功删除为confirm 取消为 cancel
      if (confirmResult != "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post(
        "/chemical/natural/deleteNaturalProduct",
        productNaturalId
      );
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },
    async getTypeList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/typeList",
        this.queryTypeInfo
      );
      console.log("结果：" + res);
      this.typeList = res.resultBody.data.list; // 将返回数据赋值
    },
    async getWarehouseList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/warehouseList",
        this.queryWarehouseInfo
      );
      console.log("结果：" + res);
      this.warehouseList = res.resultBody.data.list; // 将返回数据赋值
    },
  },
};
</script>
<style lang="less" scoped>
.a {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.b {
  width: 60%;
  margin-right: 20px;
}
</style>