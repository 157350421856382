<template>
  <el-dialog
    :visible.sync="showPriceDialog"
    title="添加价格"
    :destroy-on-close="true"
  >
    <el-form ref="form" label-width="150px" :model="priceForm" :rules="formRule">
      <el-row :gutter="10">
        <el-col :span="13">
          <el-form-item label="数量区间起始值(>)">
            <el-input v-model="priceForm.min" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item label="数量区间最大值(<=)" prop="max">
            <el-input v-model.number="priceForm.max" :disabled="isLastPrice" />
            不设上限
            <el-switch
              v-model="isLastPrice"
              @change="switchChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item label="价格" prop="price">
            <el-input v-model="priceForm.price" oninput="value=value.replace(/[^0-9.]/g,'')" />
          </el-form-item>
        </el-col>
        <el-col :span="13">
          <el-form-item label="积分" prop="score">
            <el-input placeholder="每吨赠送的积分" v-model="priceForm.score" oninput="value=value.replace(/[^0-9.]/g,'')" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <!-- <el-button type="primary" @click="confilm">取消</el-button> -->
      <el-button type="primary" @click="confilm">确定</el-button>
    </template>
  </el-dialog>

</template>

<script>
export default {
  data() {
    var validMax = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('数量值不能为空'))
      }
      if (!Number.isInteger(value)) {
        console.log(value)
        callback(new Error('请输入数字值'))
      } else {
        if (value <= this.priceForm.min) {
          callback(new Error('数量必须大于' + this.priceForm.min))
        } else if (value > 99999) {
          callback(new Error('数量必须小于99999'))
        } else {
          callback()
        }
      }
    }
    return {
      priceForm: null,
      isLastPrice: false,
      showPriceDialog: true,
      formRule: {
        max: [
          { required: true, validator: validMax, trigger: 'change' }
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' }
        ],
        score: [
          { required: true, message: '请输入积分', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init() { // 初始化, 返回一个promise
      this.showPriceDialog = true
      return new Promise((resolve, reject) => {
        this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
      })
    },
    switchChange(val) {
      this.priceForm.max = val ? 99999 : ''
    },
    confilm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.promise.resolve(this.priceForm)
          this.showPriceDialog = false
          this.destroyVm()
          this
        } else {
          console.log('error submit')
        }
      })
    },
    destroyVm() { // 销毁
      this.showPriceDialog = false
      setTimeout(() => { // 动画完成后执行
        this.$destroy(true) // 完全销毁一个实例。清理它与其它实例的连接，解绑它的全部指令及事件监听器
        this.$el && this.$el.parentNode.removeChild(this.$el) // 从dom节点删除
      }, 500)
    }
  }
}
</script>

<style>

</style>
