<template>
  <div class="home-container">
    <el-container
      style="width: 100%; height: 100%; position: absolute"
      direction="vertical"
    >
      <!--头部布局-->
      <el-header>
        <div>
          <img src="../assets/logo.png" alt class="logo_img" />
          <!-- <span>凯米科管理系统</span> -->
        </div>
        <div>
          <div class="left">{{ sday }}</div>
          <div class="right">{{ storeName }}</div>
          <div class="middle">{{ userName }}</div>
          <el-button type="info" @click="logout">安全退出</el-button>
        </div>
      </el-header>
      <!--侧边布局-->
      <el-container>
        <el-aside :width="isCollapse ? '64px' : '200px'">
          <!--伸缩按钮-->
          <div class="toggle-button" @click="toggleCollapase">|||</div>
          <!--侧边栏菜单区 unique-opened="true" 只保持一个菜单展开 router开启路由 active-text-color 颜色-->
          <el-menu
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#409eff"
            unique-opened
            :collapse="isCollapse"
            :collapse-transition="false"
            :router="true"
            :default-active="activePath"
            :default-openeds="openeds"
          >
            <el-submenu
              :index="item.id + ''"
              v-for="item in menuList"
              :key="item.id"
            >
              <template slot="title">
                <i :class="[item.icon]"></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                :index="it.url + ''"
                v-for="it in item.sysPermissions"
                :key="it.id"
                @click="saveNavState(it.url + '')"
              >
                <template slot="title">
                  <i :class="[it.icon]"></i>
                  <span>{{ it.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <!--主体布局-->
          <el-main>
            <!--路由占位符-->
            <router-view></router-view>
          </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      defaultHeight: {
        height: "",
      },
      sday: "",
      storeName: "",
      userName: "",
      openeds: [],
      // 左侧菜单
      menuList: [],
      iconsObject: {
        1: "iconfont iconguanliyuan",
        11: "iconfont iconsport",
        101: "iconfont icondenglu",
        102: "iconfont iconmima",
        103: "iconfont iconsport",
        104: "iconfont iconshangpin",
        201: "iconfont iconshu",
        202: "iconfont iconkaluli",
        203: "iconfont iconshiwu",
        204: "iconfont icondenglu",
      },
      isCollapse: false,
      // 被激活的连接
      activePath: "",
    };
  },
  // 类似onload
  created() {
    this.getMenuList();
    this.activePath = window.sessionStorage.getItem("activePath"); // 取出session里的访问路径
    this.timeId = setInterval(() => {
      this.sday = dayjs().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
    this.userName = JSON.parse(
      window.sessionStorage.getItem("userInfo")
    ).userName;
    this.openeds = [window.sessionStorage.getItem("openeds")];
  },

  methods: {
    // 显示当前时间
    logout() {
      //清楚服务器缓存
      this.loginoutServer();
    },
    async loginoutServer() {
      const { data: res } = await this.$http.post("/pipi/sys/logout");
      if (res.resultCode != 200) {
        return this.$message.error("退出失败！！！");
      } else {
        window.sessionStorage.clear();
        this.$router.push("/login");
        return this.$message.success("退出成功！！！");
      }
    },
    // 获取所有的导航菜单
    async getMenuList() {
      var loading = this.$loading({
        lock: true,
        text: "加载中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/menu/list");
      if (res.resultCode != 200) return this.$message.error("操作失败！！！");
      this.menuList = res.resultBody.data;
      loading.close()
    },
    // 切换菜单折叠与展开
    toggleCollapase() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存二级菜单的路径
    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath); // 存放点击的二级菜单
      this.activePath = activePath; // 给点击的菜单添加高亮
    },
  },
};
</script>
<style lang="less" scoped>
html,
#app,
.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
.el-container {
  /*设置内部填充为0，几个布局元素之间没有间距*/
  padding: 0px;
  /*外部间距也是如此设置*/
  margin: 0px;
  /*统一设置高度为100%*/
  height: 100%;
  overflow: hidden;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between; // 左右贴边
  padding-left: 0%; // 左边界
  align-items: center; // 水平
  color: #fff;
  font-size: 20px;
  width: 100%;
  > div {
    //左侧div加布局
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}

.left {
  margin-right: 20px;
}

.right {
  margin-right: 15px;
}

.middle {
  margin-right: 10px;
}

.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: none; // 对其右边框
  }
}
.el-main {
  background-color: #eaedf1;
    overflow: auto;
}

.logo_img {
  width: 40%;
  // border-radius: 50%;
}
.iconfont {
  margin-right: 10px;
}
.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer; // 显示鼠标指针为：小手
}
</style>