import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './css/global.css'
import vuePicturePreview from 'vue-picture-preview'
Vue.use(vuePicturePreview)
import ElementUI from 'element-ui';
// 修改 el-dialog 默认点击遮照为不关闭
ElementUI.Dialog.props.closeOnClickModal.default = false

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)

//引用富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
 //引入富文本css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//富文本编辑器添加实例
Vue.use(VueQuillEditor)

import AddPriceDialog from '@/components/AddPrice/index'
Vue.use(AddPriceDialog)

//import './assets/css/global.css'
// 导入iconfront
//import './assets/font/iconfont.css'
// 导入axios
import axios from 'axios'
// 挂载axios 到Vue的原型prototype的$http
Vue.prototype.$http = axios
// 设置请求的根路径
axios.defaults.baseURL = "https://admin.tuanhuake.cn/mall-admin/"
// axios.defaults.baseURL = "http://localhost:11000/"
axios.interceptors.request.use(config => {
  // 请求头挂载信息
  var token = window.sessionStorage.getItem("token");
  if (token) {
    config.headers.token = token;
  }
  // 在最后必须return config
  return config;
},
error => {
  router.push('/login');
  return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  let code = response.data.resultCode;
  if (code === 1014 || code == 1059 || code == 1060) {
    router.push('/login');
    return Promise.reject(response.data.resultMsg);
  }
  return response;
}, function (error) {
  // 对响应错误做点什么
  // router.push('/login');
  return Promise.reject(error);
});
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')



