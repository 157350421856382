<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      <el-breadcrumb-item>企业审核</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="6">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入公司名称"
            v-model="queryInfo.companyName"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <!-- <el-col :span="3">
          <el-select
            v-model="queryInfo.companyAudit"
            clearable
            placeholder="实名状态"
            @clear="getUserList(1)"
          >
            <el-option
              v-for="item in orderStateList"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-col> -->
        <el-col :span="0.5">
          <el-button icon="el-icon-search" @click="getUserList(1)"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"
            >刷新</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>

        <el-table-column label="公司名称" prop="companyName" width="200"></el-table-column>
        <el-table-column label="申请人电话" prop="applyContract" width="200"></el-table-column>
        <el-table-column
          label="公司法人姓名"
          prop="companyLegalPerson"
        ></el-table-column>
        <el-table-column width="170"
          label="公司税号"
          prop="companyIdNumber"
        ></el-table-column>
        <el-table-column label="公司注册时间" prop="companyRegistrationTime" width="160px">
        </el-table-column>
        <el-table-column label="公司有效期" prop="validityOfCompany" width="180px">
        </el-table-column>
        <el-table-column label="审核状态" prop="companyAudit">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.companyAudit == 1">未审核</el-tabs>
            <el-tabs v-if="scope.row.companyAudit == 2">通过</el-tabs>
          </template>
        </el-table-column>
        <el-table-column label="公司营业执照图片" prop="companyBusinessLicense">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.companyBusinessLicense">
            </viewer>
          </template>
        </el-table-column>

        <!-- <el-table-column label="法人正面身份证" prop="companyLegalCardFront">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.companyLegalCardFront">
            </viewer>
          </template>
        </el-table-column>

        <el-table-column label="法人反面身份证" prop="companyLegalCardBack">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.companyLegalCardBack">
            </viewer>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" width="190" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button v-if="scope.row.companyAudit == 1"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="auditCompany(scope.row, 2)"
              >通过</el-button
            >
            <el-button v-if="scope.row.companyAudit == 1"
              type="success"
              size="mini"
              icon="el-icon-delete"
              @click="auditCompany(scope.row, 3)"
              >驳回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
       orderStateList: [
        { key: "0", display_name: "未填写" },
        { key: "1", display_name: "未审核" },
        { key: "2", display_name: "审核通过" },
        { key: "3", display_name: "审核未通过" },
      ],
      formMaxSize: 10, // 上传文件大小
      formFileList: [], // 显示上传文件
      uploadFormFileList: [], // 确定上传文件
      dialogVisible: false,
      dialogInfo: {},
      // 请求数据
      queryInfo: {
        info: "",
        companyAudit: "1",
        pageNo: 1,
        pageSize: 10,
      },
      queryProductInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      dialogImageUrl: "",
      comeFromlist: [
        { key: "1", display_name: "小程序" },
        { key: "2", display_name: "web" },
      ],
      typelist: [
        { key: "1", display_name: "图片" },
        { key: "2", display_name: "视频" },
      ],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {
        url: "",
      },
      productList: [],
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        url: "",
      },

      delFlag: "",
      // 验证规则
      addFormRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 5, max: 15, message: "长度在 5 到 8 个字符", trigger: "blur" },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 8, message: "长度在 6 到 8 个字符", trigger: "blur" },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 8, message: "长度在 6 到 8 个字符", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    timestampToTime(row) {
      let date = new Date(row.createTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/userCompanyList",
        this.queryInfo
      );
      console.log("结果：" + res);
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },
    // 展示修改框
    async showEditDialog(data) {
      this.editForm = data;
      this.formFileList = [
        { name: data.url.split("/")[data.url.split("/").length - 1] },
      ];
      this.editDialogVisible = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/basic/shufflingFigureUpdate",
          this.editForm
        );
        console.log(res);
        if (res.resultCode != "200")
          return this.$message.error("操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 审核按钮
    async auditCompany(data, companyAudit) {
      if (2 == companyAudit) {
        //审核通过
        // 弹框
        const confirmResult = await this.$confirm(
          "此公司资质信息审核通过,并发送短信通知, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        // 成功删除为confirm 取消为 cancel
        if (confirmResult != "confirm") {
          return this.$message.info("已取消");
        }
      }

      if (3 == companyAudit) {
        //审核不通过
        // 弹框
        const confirmResult = await this.$confirm(
          "此公司资质信息审核未通过,并发送短信通知,此条记录将被删除,是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        // 成功删除为confirm 取消为 cancel
        if (confirmResult != "confirm") {
          return this.$message.info("已取消审核");
        }
      }
      var params = {
        companyId: data.companyId,
        userId: data.userId,
        companyAudit: companyAudit,
      };
      const { data: res } = await this.$http.post(
        "/chemical/basic/auditUserCompany",
        params
      );
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },
  },
};
</script>
<style lang="less" scoped>
</style>