<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>业务管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="1.5">
          <el-button
            type="primary"
            @click="
              addDialogVisible = true;
              clearFields();
            "
            >添加商品</el-button
          >
        </el-col>
        <el-col :span="4">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入商品名称"
            v-model="queryInfo.productName"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <el-col :span="6">
          <!-- 搜索添加 -->
          <el-select
            v-model="queryInfo.isProductShelves"
            clearable
            placeholder="请选择上下架类型"
            @clear="getUserList(1)"
          >
            <el-option
              v-for="item in isProductShelvesList"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-col>
        <el-col :span="0.5">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="商品名称" prop="productName"></el-table-column>
        <el-table-column
          label="所属分类"
          prop="productTypeName"
        ></el-table-column>
        <el-table-column label="商品封面图片" prop="productMainImage">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.productMainImage" />
            </viewer>
          </template>
        </el-table-column>
        <el-table-column label="商品详情图片" prop="productContent">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.productContent" />
            </viewer>
          </template>
        </el-table-column>
        <!-- <el-table-column label="售后保证" prop="content" show-overflow-tooltip></el-table-column> -->
        <el-table-column
          width="140"
          label="添加时间"
          prop="createTime"
          :formatter="timestampToTime"
        ></el-table-column>
        <el-table-column label="热销商品" prop="isHotProduct">
          <!-- scope.row 就是当前行的信息 -->
          <template slot-scope="scope">
            <el-switch
              active-color="#409EFF"
              inactive-color="#C0CCDA"
              v-model="scope.row.isHotProduct"
              active-value="1"
              inactive-value="0"
              @change="userStateChanged($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="上架商品" prop="isProductShelves">
          <!-- scope.row 就是当前行的信息 -->
          <template slot-scope="scope">
            <el-switch
              active-color="#409EFF"
              inactive-color="#C0CCDA"
              v-model="scope.row.isProductShelves"
              active-value="1"
              inactive-value="0"
              @change="userStateChanged($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="priceAudit">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.priceAudit == 1">未审核</el-tabs>
            <el-tabs v-if="scope.row.priceAudit == 2">审核通过</el-tabs>
            <el-tabs v-if="scope.row.priceAudit == 3">审核未通过</el-tabs>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
              >修改</el-button
            >

            <!-- 修改 -->
            <el-button
              type="danger"
              icon="el-icon-edit"
              size="mini"
              @click="deleteEditDialog(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 创建新用户对话框 -->
    <el-dialog
      title="添加商品信息"
      :visible.sync="addDialogVisible"
      v-if="addDialogVisible"
      width="90%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <!-- 用户名 -->
        <!-- <el-divider style="color: red">商品销售属性，必填</el-divider> -->
        <div class="a">
          <el-form-item class="b" label="上下架" prop="isProductShelves">
            <el-select
              placeholder="请选择类型"
              v-model="addForm.isProductShelves"
              clearable
            >
              <el-option
                v-for="item in isProductShelvesList"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="b" label="商品名称" prop="productName">
            <el-input
              placeholder="请填写商品名称"
              v-model="addForm.productName"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
        </div>
        <div class="a">
          <el-form-item class="b" label="选择类型" prop="productType">
            <el-select
              :data="typeList"
              placeholder="请选择类型"
              clearable
              v-model="addForm.productType"
            >
              <el-option
                v-for="item in typeList"
                :key="item.typeId"
                :label="item.name"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item class="b" label="商品详情图" prop="formFileList1">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="addForm.formFileList1"
              :file-list="addForm.formFileList1"
              :http-request="handleUploadForm1"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductContent"
              ><el-button type="primary">上传简介图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="热销商品" prop="isHotProduct">
            <el-select
              placeholder="请选择类型"
              v-model="addForm.isHotProduct"
              clearable
            >
              <el-option
                v-for="item in isHotProductList"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="b" label="商品封面图" prop="formFileList2">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="addForm.formFileList2"
              :file-list="addForm.formFileList2"
              :http-request="handleUploadForm2"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductMainProduct"
              ><el-button type="primary">上传缩略图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="轮播图" prop="productPhotoAlbums">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG,.avi,.mp4"
              action
              :limit="10"
              v-model="addForm.productPhotoAlbums"
              :file-list="addForm.productPhotoAlbums"
              :http-request="handleUploadForm3"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              ><el-button type="primary">上传多张图片</el-button></el-upload
            >
          </el-form-item>
        </div>
        <el-divider style="color: red">规格设置</el-divider>
        <div class="a">
          <el-form-item label="商品规格:">
            <el-radio-group v-model="addForm.useAttr">
              <el-radio :label="0">单规格</el-radio>
              <el-radio :label="1">多规格</el-radio>
            </el-radio-group>
            <span style="color:#F56C6C; margin-left: 10px"
              >(提交后不可修改,请谨慎选择)</span
            >
          </el-form-item>
        </div>
        <div class="a" v-if="addForm.useAttr">
          <el-form-item label="选择规格:">
            <el-select v-model="attrValue" @change="attrChange">
              <el-option
                v-for="item in attrList"
                :key="item.id"
                :label="item.name"
                :value="item.item"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="a">
          <SkuForm
            style="width: 80%"
            ref="skuForm"
            :structure="structure"
            :source-attribute="sourceAttribute"
            :attribute.sync="addForm.attributeList"
            :sku.sync="addForm.sku"
          >
            <template #price="slotProps">
              <el-table :data="slotProps.row.price" border="">
                <el-table-column label="售价">
                  <template slot-scope="priceDataData">
                    <el-input v-model="priceDataData.row.productPrice" />
                  </template>
                </el-table-column>
                <el-table-column
                  label="数量区间"
                >
                <template slot-scope="priceDataData">
                  {{ priceDataData.row.productSpecificationsMin + '~' + priceDataData.row.productSpecificationsMax }}
                </template>
              </el-table-column>>
                <el-table-column
                  prop="productSendIntegral"
                  label="每吨赠送积分"
                />
                <el-table-column align="center" label="操作">
                  <template slot-scope="scopedData">
                    <el-button
                      type="text"
                      size="small"
                      @click="
                        slotProps.row.price.splice(
                          scopedData.$index,
                          slotProps.row.price.length - scopedData.$index
                        )
                      "
                      >删除</el-button
                    >
                    <el-button
                      v-if="
                        slotProps.row.price.length == scopedData.$index + 1 &&
                          scopedData.row.productSpecificationsMax < 99999
                      "
                      type="text"
                      size="small"
                      @click="addPriceClick(slotProps.row)"
                      >新增价格</el-button
                    >
                  </template>
                </el-table-column>
                <template slot="empty">
                  <el-button
                    size="small"
                    type="text"
                    @click="addPriceClick(slotProps.row)"
                    >新增价格</el-button
                  >
                </template>
              </el-table>
            </template>
            <template #canSale="slotSale">
              <el-switch
                v-model.number="slotSale.row.canSale"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </template>
          </SkuForm>
        </div>
      </el-form>
      <!-- 内容底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改商品"
      :visible.sync="editDialogVisible"
      width="90%"
      @close="editDialogClosed"
    >
      <el-form
        v-if="editDialogVisible"
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <!-- <el-divider style="color: red">商品销售属性，必填</el-divider> -->
        <div class="a">
          <el-form-item class="b" label="上下架" prop="isProductShelves">
            <el-select
              placeholder="请选择类型"
              v-model="editForm.isProductShelves"
              clearable
            >
              <el-option
                v-for="item in isProductShelvesList"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="b" label="商品名称" prop="productName">
            <el-input
              placeholder="请填写商品名称"
              v-model="editForm.productName"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
        </div>
        <div class="a">
          <el-form-item class="b" label="选择类型" prop="productType">
            <el-select
              :data="typeList"
              placeholder="请选择类型"
              clearable
              v-model="editForm.productType"
            >
              <el-option
                v-for="item in typeList"
                :key="item.typeId"
                :label="item.name"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item class="b" label="商品详情图" prop="formFileList1">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="editForm.formFileList1"
              :file-list="editForm.formFileList1"
              :http-request="handleUploadForm4"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductContentUpdate"
              ><el-button type="primary">上传简介图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="热销商品" prop="isHotProduct">
            <el-select
              placeholder="请选择类型"
              v-model="editForm.isHotProduct"
              clearable
            >
              <el-option
                v-for="item in isHotProductList"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="b" label="商品封面图" prop="formFileList2">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="editForm.formFileList2"
              :file-list="editForm.formFileList2"
              :http-request="handleUploadForm5"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductMainProductUpdate"
              ><el-button type="primary">上传缩略图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <el-divider style="color: red">商品轮播图，必选</el-divider>
        <div class="a">
          <el-form-item class="b" label="上传相册" prop="productPhotoAlbums">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG,.avi,.mp4"
              action
              :limit="10"
              v-model="editForm.productPhotoAlbums"
              :file-list="editForm.productPhotoAlbums"
              :http-request="handleUploadForm6"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveUpdate"
              ><el-button type="primary">上传多张图片</el-button></el-upload
            >
          </el-form-item>
        </div>
        <el-divider style="color: red"
          >规格设置</el-divider
        >
        <div class="a" v-if="editForm.useAttr">
          <el-form-item label="选择规格:">
            <el-select v-model="editAttrValue" @change="editAttrChange">
              <el-option
                v-for="item in attrList"
                :key="item.id"
                :label="item.name"
                :value="item.item"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="a">
          <SkuForm
            style="width: 80%"
            ref="editSkuFormRef"
            :structure="editStructure"
            :source-attribute="editSourceAttribute"
            :attribute.sync="editForm.attributeList"
            :sku.sync="editForm.sku"
          >
            <template #price="slotProps">
              <el-table  :data="slotProps.row.price" border="">
                <el-table-column
                  label="数量区间"
                >
                <template slot-scope="priceDataData">
                  {{ priceDataData.row.productSpecificationsMin + '~' + priceDataData.row.productSpecificationsMax }}
                </template>
              </el-table-column>
                <el-table-column
                  prop="productPrice"
                  label="售价"
                >
                <template slot-scope="scopeData">
                  <el-input v-model="scopeData.row.productPrice" />
                </template>
              </el-table-column>
                <el-table-column
                  prop="productSendIntegral"
                  label="赠送积分"
                />
                <el-table-column align="center" label="操作">
                  <template slot-scope="scopedData">
                    <el-button
                      type="text"
                      size="small"
                      @click="
                        slotProps.row.price.splice(
                          scopedData.$index,
                          slotProps.row.price.length - scopedData.$index
                        )
                      "
                      >删除</el-button
                    >
                    <el-button
                      v-if="
                        slotProps.row.price.length == scopedData.$index + 1 &&
                          scopedData.row.productSpecificationsMax < 99999
                      "
                      type="text"
                      size="small"
                      @click="addPriceClick(slotProps.row)"
                      >新增价格</el-button
                    >
                  </template>
                </el-table-column>
                <template slot="empty">
                  <el-button
                    size="small"
                    type="text"
                    @click="addPriceClick(slotProps.row)"
                    >新增价格</el-button
                  >
                </template>
              </el-table>
            </template>
            <template #canSale="slotSale">
              <el-switch
                v-model.number="slotSale.row.canSale"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </template>
            <template #id="slotData">
              {{ slotData.row.id }}
            </template>
          </SkuForm>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SkuForm from "vue-sku-form";

const defaultStructure = [
  {
    name: "price",
    type: "slot",
    defaultValue: [],
    label: "价格",
    required: true,
    validate: (data, index, callback) => {
      if (
        data[index].price[data[index].price.length - 1]
          .productSpecificationsMax != 99999
      ) {
        callback(new Error("价格不完整, 最后一项需要选择 不设上限"));
      }

      callback();
    },
  },
  {
    name: "canSale",
    type: "slot",
    defaultValue: 1,
    label: "是否售卖1",
  }
];

const editStructure = [
  {
    name: "price",
    type: "slot",
    defaultValue: [],
    label: "价格",
    required: true,
    validate: (data, index, callback) => {
      if (
        data[index].price[data[index].price.length - 1]
          .productSpecificationsMax != 99999
      ) {
        callback(new Error("价格不完整, 最后一项需要选择 不设上限"));
      }

      callback();
    },
  },
  {
    name: "canSale",
    type: "slot",
    defaultValue: 1,
    label: "是否售卖1",
  },
  {
    name: "id",
    type: "slot",
    defaultValue: '',
    label: "id",
  },
];

export default {
  components: { SkuForm },
  data() {
    return {
      editorOption: {
        placeholder: "请输入正文",
      },
      formMaxSize: 10, // 上传文件大小
      uploadFormFileList: [], // 确定上传文件
      // 请求数据
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      queryTypeInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      queryWarehouseInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      isProductShelvesList: [
        { key: "1", display_name: "上架" },
        { key: "0", display_name: "下架" },
      ],
      isHotProductList: [
        { key: "1", display_name: "是" },
        { key: "0", display_name: "否" },
      ],
      fileTypeList: [
        { key: "1", display_name: "图片" },
        { key: "2", display_name: "视频" },
      ],
      typeList: [],
      warehouseList: [],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      attrList: [],
      attrValue: null,
      sourceAttribute: [],
      editSourceAttribute: [],
      structure: JSON.parse(JSON.stringify(defaultStructure)),
      editStructure: JSON.parse(JSON.stringify(editStructure)),
      editAttrValue: null,
      // 添加用户表单项
      addForm: {
        content: "",
        productName: "",
        productType: "",
        isHotProduct: "",
        isProductShelves: "",
        fileType: "1",
        productPhotoAlbums: [],
        productPriceDataList: [],
        useAttr: 0,
        attributeList: [],
        sourceAttribute: [],
        sku: [],
        formFileList1: [], // 显示上传文件
        formFileList2: [], // 显示上传文件
      },

      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        content: "",
        productName: "",
        productType: "",
        isHotProduct: "",
        isProductShelves: "",
        fileType: "1",
        productPhotoAlbums: [],
        productPriceDataList: [],
        useAttr: 0,
        attributeList: [],
        sku: [],
        formFileList1: [], // 显示上传文件
        formFileList2: [], // 显示上传文件
      },
      // 验证规则
      addFormRules: {
        productName: [
          { required: true, message: "请填写商品名称", trigger: "blur" },
        ],
        productType: [
          { required: true, message: "请选择商品类型", trigger: "blur" },
        ],
        isHotProduct: [
          { required: true, message: "请选择是否是热销商品", trigger: "blur" },
        ],
        productPriceDataList: [
          { required: true, message: "请添加至少一个规格", trigger: "blur" },
        ],
        formFileList1: [
          {
            required: true,
            message: "请上传一张商品详情图片",
            trigger: "blur",
          },
        ],
        isProductShelves: [
          { required: true, message: "请选择上下架类型", trigger: "blur" },
        ],
        formFileList2: [
          { required: true, message: "请上传一张缩略图", trigger: "blur" },
        ],
        fileType: [
          { required: true, message: "请选择轮播相册类型", trigger: "blur" },
        ],
        productPhotoAlbums: [
          {
            required: true,
            message: "请上传多张图片",
            trigger: "blur",
          },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        productName: [
          { required: true, message: "请填写商品名称", trigger: "blur" },
        ],
        productType: [
          { required: true, message: "请选择商品类型", trigger: "blur" },
        ],
        isHotProduct: [
          { required: true, message: "请选择是否是热销商品", trigger: "blur" },
        ],
        productPriceDataList: [
          { required: true, message: "请添加至少一个规格", trigger: "blur" },
        ],
        formFileList1: [
          {
            required: true,
            message: "请上传一张商品详情图片",
            trigger: "blur",
          },
        ],
        isProductShelves: [
          { required: true, message: "请选择上下架类型", trigger: "blur" },
        ],
        formFileList2: [
          { required: true, message: "请上传一张缩略图", trigger: "blur" },
        ],
        fileType: [
          { required: true, message: "请选择轮播相册类型", trigger: "blur" },
        ],
        productPhotoAlbums: [
          {
            required: true,
            message: "请上传多张图片",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getUserList();
    this.getTypeList();
    this.getAttrList();
  },
  methods: {
    handleSelectChange() {
      this.$forceUpdate();
    },
    handleDeleteAllDetails() {
      this.addForm.productPriceDataList = undefined;
      console.log(
        "清空后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },
    //单选框选中数据
    handleDetailSelectionChange(selection) {
      if (selection.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(selection.pop());
      } else {
        this.checkedDetail = selection;
      }
    },
    handleAddDetails() {
      if (this.addForm.productPriceDataList == undefined) {
        this.addForm.productPriceDataList = new Array();
      }
      let obj = {};
      obj.productSpecifications = "";
      obj.productSpecificationsMin = "";
      obj.productSpecificationsMax = "";
      obj.productPrice = "";
      obj.productSendIntegral = "";
      this.addForm.productPriceDataList.push(obj);
      console.log(
        "增加后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    handleDeleteDetails() {
      if (this.checkedDetail.length == 0) {
        this.$alert("请先选择要删除的数据", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.addForm.productPriceDataList.splice(
          this.checkedDetail[0].xh - 1,
          1
        );
      }
    },

    handleDeleteAllDetailsUpdate() {
      this.editForm.productPriceDataList = undefined;
      console.log(
        "清空后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },
    handleAddDetailsUpdate() {
      if (this.editForm.productPriceDataList == undefined) {
        this.editForm.productPriceDataList = new Array();
      }
      let obj = {};
      obj.productSpecifications = "";
      obj.productSpecificationsMin = "";
      obj.productSpecificationsMax = "";
      obj.productPrice = "";
      obj.productSendIntegral = "";
      this.editForm.productPriceDataList.push(obj);
      console.log(
        "增加后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },
    handleDeleteDetailsUpdate() {
      if (this.checkedDetail.length == 0) {
        this.$alert("请先选择要删除的数据", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.editForm.productPriceDataList.splice(
          this.checkedDetail[0].xh - 1,
          1
        );
      }
      console.log(
        "删除后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },

    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryNaturalProduct",
        this.queryInfo
      );
      console.log("结果：" + res);
      res.resultBody.data.list.forEach((item) => {
        item['attributeList'] = JSON.parse(item['attributeList'])
      });
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      
      this.total = res.resultBody.data.total; // 总个数
    },
    getAttrList() {
      this.$http
        .get("/chemical/attr/get")
        .then((res) => {
          this.attrList = res.data.resultBody.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    timestampToTime(row) {
      let date = new Date(row.createTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    //修改用户状态
    async userStateChanged(data, b) {
      const { data: res } = await this.$http.post(
        "/chemical/natural/updateNaturalProduct",
        b
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
    },
    // 监听添加用户
    addDialogClosed() {
      this.addForm.content = "";
      this.addForm.fileType = "";
      this.addForm.productPhotoAlbums = [];
      this.addForm.formFileList1 = [];
      this.addForm.formFileList2 = [];
      this.addForm.productName = "";
      this.addForm.productType = "";
      this.addForm.isHotProduct = "";
      this.addForm.isProductShelves = "";
      this.addForm.productPriceDataList = [];
    },
    clearFields() {
      this.addForm.content = "";
      this.addForm.fileType = "";
      this.addForm.productPhotoAlbums = [];
      this.addForm.formFileList1 = [];
      this.addForm.formFileList2 = [];
      this.addForm.productName = "";
      this.addForm.productType = "";
      this.addForm.isHotProduct = "";
      this.addForm.isProductShelves = "";
      this.addForm.productPriceDataList = [];
    },
    handlePreview(file) {
      console.log(file);
    },
    handleRemoveProductContent(file, formFileList1) {
      this.addForm.formFileList1 = formFileList1;
      console.log("移除之后formFileList1：" + this.addForm.formFileList1);
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductMainProduct(file, formFileList2) {
      this.addForm.formFileList2 = formFileList2;
      console.log("移除之后formFileList2：" + this.addForm.formFileList2);
    },
    handleRemove(file, productPhotoAlbums) {
      this.addForm.productPhotoAlbums = productPhotoAlbums;
      console.log(
        "移除之后productPhotoAlbums：" + this.addForm.productPhotoAlbums
      );
    },
    handleRemoveUpdate(file, productPhotoAlbums) {
      this.editForm.productPhotoAlbums = productPhotoAlbums;
      console.log(
        "移除之后productPhotoAlbums：" + this.editForm.productPhotoAlbums
      );
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductContentUpdate(file, formFileList1) {
      this.editForm.formFileList1 = formFileList1;
      console.log("移除之后formFileList1：" + formFileList1);
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductMainProductUpdate(file, formFileList2) {
      this.editForm.formFileList2 = formFileList2;
      console.log("移除之后formFileList2：" + this.editForm.formFileList2);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    startvideo() {
      if (this.$refs.startvideo.paused) {
        this.$refs.startvideo.play();
      } else {
        this.$refs.startvideo.play();
      }
    },
    startPic() {},
    // 允许上传文件个数验证
    formHandleExceed(files, formFileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + formFileList.length} 个文件`
      );
    },
    // 上传文件
    // 上传文件
    async handleUploadForm1(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "3"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.addForm.formFileList1 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productContent: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.addForm.formFileList1 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm2(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "3"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.addForm.formFileList2 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productMainImage: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.addForm.formFileList2 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm3(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "3"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        console.log("res.resultBody.data:" + res.resultBody.data);
        thiz.addForm.productPhotoAlbums.push({
          fileType: thiz.addForm.fileType,
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productFileUrl: res.resultBody.data,
          url: res.resultBody.data,
        });
        thiz.uploadFormFileList = [];
        console.log(
          "thiz.addForm" + JSON.stringify(thiz.addForm.productPhotoAlbums)
        );
      } else {
        thiz.addForm.productPhotoAlbums = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },

    // 上传文件
    async handleUploadForm4(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "3"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");

        thiz.editForm.formFileList1.push({
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productContent: res.resultBody.data,
          url: res.resultBody.data,
        });
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.formFileList1 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm5(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "3"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.editForm.formFileList2 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productMainImage: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.formFileList2 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm6(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "3"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        console.log("res.resultBody.data:" + res.resultBody.data);
        thiz.editForm.productPhotoAlbums.push({
          fileType: this.editForm.fileType,
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productFileUrl: res.resultBody.data,
        });
        console.log(
          "this.editForm" + JSON.stringify(this.editForm.productPhotoAlbums)
        );
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.productPhotoAlbums = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },

    // 添加用户
    addUser() {
      var flag = 0;
      this.$refs.skuForm.validate((valid) => {
        flag = valid;
      });

      if (!flag) {
        return;
      }

      console.log("====================================");
      console.log(this.addForm);
      console.log("====================================");

      if (this.addForm.fileType == 2) {
        if (this.addForm.productPhotoAlbums.length > 1) {
          return this.$message.info("相册类型选择的是视频，只能上传一个文件");
        }
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        // 发起请求
        this.addForm.productContent = this.addForm.formFileList1[0].url;
        this.addForm.productMainImage = this.addForm.formFileList2[0].url;

        var params = Object.assign(this.addForm);
        params['attributeList'] = JSON.stringify(params['attributeList']);

        const { data: res } = await this.$http.post(
          "/chemical/natural/addNaturalProduct",
          params
        );
        if (res.resultCode != "200") {
          return this.$message(res.resultMsg);
        }
        this.$message.success("操作成功！！！");
        //隐藏
        this.addDialogVisible = false;
        this.getUserList();
      });
    },
    // 展示修改框
    async showEditDialog(tmpData) {

      var obj = {};
      var data = Object.assign({},tmpData);

      obj.name = data.productContent.split("/")[
      data.productContent.split("/").length - 1
      ];
      obj.productContent = data.productContent;
      obj.url = data.productContent;
      var arr = [];
      arr.push(obj);
      data.formFileList1 = arr;

      var obj1 = {};
      obj1.name = data.productMainImage.split("/")[
        data.productMainImage.split("/").length - 1
      ];
      obj1.productMainImage = data.productMainImage;
      obj1.url = data.productMainImage;
      var arr1 = [];
      arr1.push(obj1);
      data.formFileList2 = arr1;
      
      if (data['attributeList'].length && (data['attributeList'] != '[]')) {
        data['attributeList'] = JSON.stringify(data['attributeList'])
        data['attributeList'] = JSON.parse(data['attributeList'])
      }else {
        data['attributeList'] = []
      }

      this.editSourceAttribute = Object.assign(data['attributeList']);
      this.editForm = data;

      // this.editForm.productPriceDataList = data.productPriceDataList;
      if (this.editForm.productPhotoAlbums.length > 0) {
        this.editForm.fileType = this.editForm.productPhotoAlbums[0].fileType;
      }
      this.editForm.productType = parseInt(data.productType);

      for (var i = 0; i < this.editForm.productPhotoAlbums.length; i++) {
        var productFileUrl = this.editForm.productPhotoAlbums[i].productFileUrl;
        var filename = productFileUrl.split("/")[
          productFileUrl.split("/").length - 1
        ];
        this.editForm.productPhotoAlbums[i].name = filename;
      }
      this.editDialogVisible = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {

      var flag = 0;
      this.$refs.editSkuFormRef.validate((valid) => {
        flag = valid;
      });
      console.log(flag);
      if (!flag) {
        return;
      }

      if (this.editForm.fileType == 2) {
        if (this.editForm.productPhotoAlbums.length > 1) {
          return this.$message.info("相册类型选择的是视频，只能上传一个文件");
        }
      }
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        this.editForm.productContent = this.editForm.formFileList1[0].url;
        this.editForm.productMainImage = this.editForm.formFileList2[0].url;
        for (var i = 0; i < this.editForm.productPhotoAlbums.length; i++) {
          this.editForm.productPhotoAlbums[i].fileType = this.editForm.fileType;
        }
        var params = Object.assign({},this.editForm);
        params['attributeList'] = JSON.stringify(params['attributeList']);
        const { data: res } = await this.$http.post(
          "/chemical/natural/updateNaturalProduct",
          params
        );
        if (res.resultCode != "200")
          return this.$message.error("操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 删除按钮
    async deleteEditDialog(productNaturalData) {
      // 弹框
      const confirmResult = await this.$confirm(
        "此操作将删除该商品, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 成功删除为confirm 取消为 cancel
      if(confirmResult!='confirm'){
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post(
        "/chemical/natural/deleteNaturalProduct",
        {'productNaturalId': productNaturalData['productNaturalId']}
      );
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },
    async getTypeList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/typeList",
        this.queryTypeInfo
      );
      console.log("结果：" + res);
      this.typeList = res.resultBody.data.list; // 将返回数据赋值
    },
    async getWarehouseList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/warehouseList",
        this.queryWarehouseInfo
      );
      console.log("结果：" + res);
      this.warehouseList = res.resultBody.data.list; // 将返回数据赋值
    },
    attrChange(item) {
      
      try {
        item = JSON.parse(item)
        console.log('====================================');
        console.log(item);
        console.log('====================================');
      }catch(e) {
        console.log('====================================');
        console.log(e);
        console.log('====================================');
      }
      var arr = [];
      if (item == null) {
        return;
      }
      item.forEach((attr) => {
        const { value, item } = attr;
        arr.push({ name: value, item: item });
      });
      this.sourceAttribute = arr;
      this.addForm.sku = [];
      this.addForm.attributeList = [];
      this.structure = JSON.parse(JSON.stringify(defaultStructure));
      this.editStructure = JSON.parse(JSON.stringify(editStructure));
      this.$refs.skuForm.init();
    },
    addPriceClick(slotProps) {
      var lastMaxCount = 0;
      if (slotProps.price.length > 0) {
        lastMaxCount =
          slotProps.price[slotProps.price.length - 1].productSpecificationsMax;
      }
      this.$showPriceDailog({ min: lastMaxCount }).then((res) => {
        const { min, max, price, score } = res;
        var productSpecifications = ''
        if (max < 99999) {
          productSpecifications = min + '~' + max + '吨'
        }else {
          productSpecifications = '>' + min + '吨'
        }
        slotProps.price.push({
          productSpecifications: productSpecifications,
          productSpecificationsMin: min,
          productSpecificationsMax: max,
          productPrice: price,
          productSendIntegral: score,
        });
      });
    },
    saleStatuChange(e) {
      console.log('====================================');
      console.log(e);
      console.log(this.sku);
      console.log('====================================');
    },
    editAttrChange(item) {
      
      console.log('==================editAttrChange==================');
      console.log(item);
      console.log('====================================');
      try {
        item = JSON.parse(item)
      }catch(e) {
        console.log('====================================');
        console.log(e);
        console.log('====================================');
      }
      var arr = [];
      if (item == null) {
        return;
      }
      item.forEach((attr) => {
        const { value, item } = attr;
        arr.push({ name: value, item: item });
      });
      this.editSourceAttribute = arr;
      // this.editForm.sku = [];
      // this.editForm.attributeList = [];
      this.structure = JSON.parse(JSON.stringify(defaultStructure));
      this.editStructure = JSON.parse(JSON.stringify(editStructure));
      this.$refs.editSkuFormRef.init();
    },
  },
};
</script>
<style lang="less" scoped>
.a {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.b {
  width: 60%;
}
</style>
