<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>业务管理</el-breadcrumb-item>
      <el-breadcrumb-item>拼团管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="1.5">
          <el-button type="primary" @click="showAddGroupDataDialog"
            >添加拼团商品</el-button
          >
        </el-col>
        <el-col :span="4">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入拼团商品名称"
            v-model="queryInfo.productName"
            clearable
            @clear="getUserList(1)"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="queryInfo.groupState" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="0.5">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="商品名称">
          <template slot-scope="scopedData">
            {{ scopedData.row.productName + scopedData.row.attr }}
          </template>
        </el-table-column>
        <el-table-column label="商品封面图片" prop="productMainImage">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.productMainImage" />
            </viewer>
          </template>
        </el-table-column>
        <el-table-column label="商品详情图片" prop="productContent">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.productContent" />
            </viewer>
          </template>
        </el-table-column>
        <el-table-column label="积分" prop="score"></el-table-column>
        <el-table-column
          label="预交价格"
          prop="acceptingThePrice"
        ></el-table-column>
        <el-table-column label="单价/吨" prop="productPrice"></el-table-column>
        <el-table-column
          label="拼团结束时间"
          prop=""
          :formatter="timestampToTime"
        >
        </el-table-column>
        <el-table-column
          label="拼团成功总量（吨）"
          prop="groupTotal"
        ></el-table-column>
        <el-table-column
          label="最小购买数量（吨）"
          prop="minPurchaseQuantity"
        ></el-table-column>
        <el-table-column label="拼团状态" prop="groupState">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.groupState == 1">拼团中</el-tabs>
            <el-tabs v-if="scope.row.groupState == 2">拼团成功</el-tabs>
            <el-tabs v-if="scope.row.groupState == 3">拼团失败</el-tabs>
          </template>
        </el-table-column>
        <el-table-column label="上架商品" prop="isProductShelves">
          <!-- scope.row 就是当前行的信息 -->
          <template slot-scope="scope">
            <el-switch
              v-if="scope.row.groupState == 1"
              active-color="#409EFF"
              inactive-color="#C0CCDA"
              v-model="scope.row.isProductShelves"
              active-value="1"
              inactive-value="0"
              @change="userStateChanged($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="priceAudit">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.priceAudit == 1">未审核</el-tabs>
            <el-tabs v-if="scope.row.priceAudit == 2">审核通过</el-tabs>
            <el-tabs v-if="scope.row.priceAudit == 3">审核未通过</el-tabs>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button
              v-if="scope.row.groupState == 1"
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row)"
              >修改</el-button
            >
            <!-- <el-button
              type="primary"
              icon="el-icon-note"
              size="mini"
              >
              查看参与人
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 创建新用户对话框 -->
    <el-dialog
      title="添加拼团"
      :visible.sync="addDialogVisible"
      v-if="addDialogVisible"
      width="1000px"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域 -->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <!-- 用户名 -->
        <!-- <el-divider style="color: red">商品销售属性，必填</el-divider> -->
        <div class="a">
          <!-- <el-form-item class="b" label="商品名称" prop="productNaturalId">
            <el-select
              :data="productList"
              placeholder="请选择商品"
              clearable
              v-model="addForm.productNaturalId"
            >
              <el-option
                v-for="item in productList"
                :key="item.productNaturalId"
                :label="item.productName"
                :value="item.productNaturalId"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item class="b" label="选择商品" prop="productNaturalId">
            <el-cascader
              style="width: 100%"
              v-model="addForm.selectValue"
              :options="formatForCas(productList)"
              :props="{ expandTrigger: 'hover' }"
              @change="handleProductChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item class="b" label="">
            
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="成功数量" prop="groupTotal">
            <el-input
              placeholder=">=该数量拼团成功"
              v-model="addForm.groupTotal"
            ></el-input>
          </el-form-item>
          <el-form-item class="b" label="最大数量" prop="groupMaxCount">
            <el-input
              placeholder="请填写拼团最大数量, 购买量<=此数量"
              v-model="addForm.groupMaxCount"
            ></el-input>
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="最小购量" prop="minPurchaseQuantity">
            <el-input
              placeholder="请填写需拼团的最小吨量（大于零的正整数）"
              v-model="addForm.minPurchaseQuantity"
            ></el-input>
          </el-form-item>
          <el-form-item class="b" label="到期时间" prop="groupEndTime">
            <el-date-picker
              v-model="addForm.groupEndTime"
              type="datetime"
              clearable
              placeholder="拼团到期时间"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="单价/吨" prop="productPrice">
            <el-input
              placeholder="请填写金额"
              v-model="addForm.productPrice"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item class="b" label="商品详情图" prop="formFileList1">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="addForm.formFileList1"
              :file-list="addForm.formFileList1"
              :http-request="handleUploadForm1"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductContent"
              ><el-button type="primary">上传简介图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="上下架" prop="isProductShelves">
            <el-select
              placeholder="请选择类型"
              v-model="addForm.isProductShelves"
              clearable
            >
              <el-option
                v-for="item in isProductShelvesList"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="b" label="商品封面图" prop="formFileList2">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="addForm.formFileList2"
              :file-list="addForm.formFileList2"
              :http-request="handleUploadForm2"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductMainProduct"
              ><el-button type="primary">上传缩略图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="赠送积分" prop="score">
            <el-input
              placeholder="每吨赠送的积分"
              v-model="addForm.score"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item class="b"></el-form-item>
        </div>
        <el-divider style="color: red">商品轮播图，必选</el-divider>
        <div class="a">
          <el-form-item class="b" label="上传相册" prop="productPhotoAlbums">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="10"
              v-model="addForm.productPhotoAlbums"
              :file-list="addForm.productPhotoAlbums"
              :http-request="handleUploadForm3"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              ><el-button type="primary">上传多张图片</el-button></el-upload
            >
          </el-form-item>
        </div>
      </el-form>
      <!-- 内容底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户对话框 -->
    <el-dialog
      title="修改拼团"
      :visible.sync="editDialogVisible"
      v-if="editDialogVisible"
      width="850px"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <!-- <el-divider style="color: red">商品销售属性，必填</el-divider> -->
        <div class="a">
          <!-- <el-form-item class="b" label="商品名称" prop="productNaturalId">
            <el-select
              :data="productList"
              placeholder="请选择商品"
              clearable
              v-model="editForm.productNaturalId"
            >
              <el-option
                v-for="item in productList"
                :key="item.productNaturalId"
                :label="item.productName"
                :value="item.productNaturalId"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item class="b" label="选择商品" prop="productNaturalId">
            <el-cascader
              style="width: 100%"
              v-model="editForm.selectValue"
              :options="formatForCas(productList)"
              :props="{ expandTrigger: 'hover' }"
              :disabled="true"
            ></el-cascader>
          </el-form-item>
          <el-form-item class="b" label="">
          </el-form-item>
          <!-- 密码 -->
        </div>
        <div class="a">
          <el-form-item class="b" label="拼团成功总量" prop="groupTotal">
            <el-input
              placeholder="请填写拼团成功数量(>=)"
              v-model="editForm.groupTotal"
            ></el-input>
          </el-form-item>
          <el-form-item class="b" label="最大数量" prop="groupMaxCount">
            <el-input
              placeholder="请填写拼团最大数量(<=)"
              v-model="editForm.groupMaxCount"
            ></el-input>
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="最小购量" prop="minPurchaseQuantity">
            <el-input
              placeholder="请填写需拼团的最小吨量（大于零的正整数）"
              v-model="editForm.minPurchaseQuantity"
            ></el-input>
          </el-form-item>
          <el-form-item class="b" label="到期时间" prop="groupEndTime">
            <el-date-picker
              v-model="editForm.groupEndTime"
              type="datetime"
              clearable
              placeholder="到期时间"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="单价/吨" prop="productPrice">
            <el-input
              placeholder="请填写金额"
              v-model="editForm.productPrice"
            ></el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item class="b" label="商品详情图" prop="formFileList1">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="editForm.formFileList1"
              :file-list="editForm.formFileList1"
              :http-request="handleUploadForm4"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductContentUpdate"
              ><el-button type="primary">上传简介图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="商品封面图" prop="formFileList2">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
              action
              :limit="1"
              v-model="editForm.formFileList2"
              :file-list="editForm.formFileList2"
              :http-request="handleUploadForm5"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveProductMainProductUpdate"
              ><el-button type="primary">上传缩略图</el-button></el-upload
            >
          </el-form-item>
        </div>
        <div class="a">
          <el-form-item class="b" label="赠送积分" prop="score">
            <el-input
              placeholder="每吨赠送的积分"
              v-model="editForm.score"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item class="b"></el-form-item>
        </div>
        <el-divider style="color: red">商品轮播图，必选</el-divider>
        <div class="a">
          <!-- <el-form-item class="b" label="相册类型" prop="fileType">
            <el-select
              placeholder="请选择类型"
              v-model="editForm.fileType"
              clearable
              @change="handleSelectChange"
            >
              <el-option
                v-for="item in fileTypeList"
                :key="item.key"
                :label="item.display_name"
                :value="item.key"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item class="b" label="上传相册" prop="productPhotoAlbums">
            <el-upload
              accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG,.avi,.mp4"
              action
              :limit="10"
              v-model="editForm.productPhotoAlbums"
              :file-list="editForm.productPhotoAlbums"
              :http-request="handleUploadForm6"
              :on-exceed="formHandleExceed"
              :on-preview="handlePreview"
              :on-remove="handleRemoveUpdate"
              ><el-button type="primary">上传多张图片</el-button></el-upload
            >
          </el-form-item>
        </div>
        <!-- <el-divider style="color: red">售后保证，必填</el-divider>
        <el-form-item>
          <quill-editor :options="editorOption" v-model="editForm.content">
          </quill-editor>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: "0",
      options: [
        {
          name: "全部",
          value: "",
        },
        {
          name: "拼团中",
          value: "1",
        },
        {
          name: "拼团成功",
          value: "2",
        },
        {
          name: "拼团失败",
          value: "3",
        },
      ],
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      editorOption: {
        placeholder: "请输入正文",
      },
      queryProductInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      formMaxSize: 10, // 上传文件大小
      uploadFormFileList: [], // 确定上传文件
      // 请求数据
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
        groupState: "",
      },
      productList: [],
      queryTypeInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      queryWarehouseInfo: {
        pageNo: 1,
        pageSize: 1000,
      },
      isProductShelvesList: [
        { key: "1", display_name: "上架" },
        { key: "0", display_name: "下架" },
      ],
      isHotProductList: [
        { key: "1", display_name: "是" },
        { key: "0", display_name: "否" },
      ],
      fileTypeList: [
        { key: "1", display_name: "图片" },
        { key: "2", display_name: "视频" },
      ],
      typeList: [],
      warehouseList: [],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {
        content: "",
        groupTotal: "",
        groupMaxCount: "",
        acceptingThePrice: "0",
        groupEndTime: "",
        productPrice: "",
        productName: "",
        isProductShelves: "",
        fileType: "1",
        minPurchaseQuantity: "",
        productPhotoAlbums: [],
        productPriceDataList: [],
        formFileList1: [], // 显示上传文件
        formFileList2: [], // 显示上传文件
        score: "",
        selectValue: [],
        productNaturalId: "",
        skuId: "",
        attr: "",
      },

      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        content: "",
        groupTotal: "",
        acceptingThePrice: "0",
        groupEndTime: "",
        productPrice: "",
        productName: "",
        minPurchaseQuantity: "",
        isProductShelves: "",
        fileType: "1",
        productPhotoAlbums: [],
        productPriceDataList: [],
        formFileList1: [], // 显示上传文件
        formFileList2: [], // 显示上传文件
        score: "",
        selectValue: [],
      },
      // 验证规则
      addFormRules: {
        productNaturalId: [
          { required: true, message: "请选择商品", trigger: "blur" },
        ],
        groupTotal: [
          {
            required: true,
            message: "请填写拼团成功数量",
            trigger: "blur",
          },
        ],
        groupMaxCount: [
          {
            required: true,
            message: "请填写拼团最大数量",
            trigger: "blur",
          },
        ],
        minPurchaseQuantity: [
          {
            required: true,
            message: "请填写最小购买数量，大于0的正整数",
            trigger: "blur",
          },
        ],
        acceptingThePrice: [
          { required: true, message: "请填写预交价格", trigger: "blur" },
        ],
        groupEndTime: [
          { required: true, message: "请填写拼团到期时间", trigger: "blur" },
        ],
        productPrice: [
          { required: true, message: "请填写金额", trigger: "blur" },
        ],
        formFileList1: [
          {
            required: true,
            message: "请上传一张商品详情图片",
            trigger: "blur",
          },
        ],
        isProductShelves: [
          { required: true, message: "请选择上下架类型", trigger: "blur" },
        ],
        formFileList2: [
          { required: true, message: "请上传一张缩略图", trigger: "blur" },
        ],
        fileType: [
          { required: true, message: "请选择轮播相册类型", trigger: "blur" },
        ],
        productPhotoAlbums: [
          {
            required: true,
            message: "请上传多张图片",
            trigger: "blur",
          },
        ],
        score: [{ required: true, message: "请填写积分", trigger: "blur" }],
      },
      // 修改用户表单验证规则
      editFormRules: {
        formFileList2: [
          { required: true, message: "请上传一张缩略图", trigger: "blur" },
        ],
        formFileList1: [
          {
            required: true,
            message: "请上传一张商品详情图片",
            trigger: "blur",
          },
        ],
        productNaturalId: [
          { required: true, message: "请选择商品", trigger: "blur" },
        ],
        groupTotal: [
          {
            required: true,
            message: "请填写拼团成功总量，大于0的正整数",
            trigger: "blur",
          },
        ],
        groupMaxCount: [
          {
            required: true,
            message: "请填写拼团最大数量",
            trigger: "blur",
          },
        ],
        minPurchaseQuantity: [
          {
            required: true,
            message: "请填写最小购买数量，大于0的正整数",
            trigger: "blur",
          },
        ],
        acceptingThePrice: [
          { required: true, message: "请填写预交价格", trigger: "blur" },
        ],
        groupEndTime: [
          { required: true, message: "请填写拼团到期时间", trigger: "blur" },
        ],
        productPrice: [
          { required: true, message: "请填写金额", trigger: "blur" },
        ],

        isProductShelves: [
          { required: true, message: "请选择上下架类型", trigger: "blur" },
        ],
        fileType: [
          { required: true, message: "请选择轮播相册类型", trigger: "blur" },
        ],
        productPhotoAlbums: [
          {
            required: true,
            message: "请上传多张图片",
            trigger: "blur",
          },
        ],
        score: [{ required: true, message: "请填写积分", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getUserList();
    this.getTypeList();
    this.getProductList();
  },
  methods: {
    handleSelectChange() {
      this.$forceUpdate();
    },
    handleDeleteAllDetails() {
      this.addForm.productPriceDataList = undefined;
      console.log(
        "清空后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1;
    },
    //单选框选中数据
    handleDetailSelectionChange(selection) {
      if (selection.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(selection.pop());
      } else {
        this.checkedDetail = selection;
      }
    },
    handleAddDetails() {
      if (this.addForm.productPriceDataList == undefined) {
        this.addForm.productPriceDataList = new Array();
      }
      let obj = {};
      obj.productSpecifications = "";
      obj.productSpecificationsMin = "";
      obj.productSpecificationsMax = "";
      obj.productPrice = "";
      obj.productSendIntegral = "";
      this.addForm.productPriceDataList.push(obj);
      console.log(
        "增加后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    handleDeleteDetails() {
      if (this.checkedDetail.length == 0) {
        this.$alert("请先选择要删除的数据", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.addForm.productPriceDataList.splice(
          this.checkedDetail[0].xh - 1,
          1
        );
      }
      console.log(
        "删除后规格列表：" + JSON.stringify(this.addForm.productPriceDataList)
      );
    },
    clearFields() {
      (this.addForm.content = ""),
        (this.addForm.groupTotal = ""),
        (this.addForm.minPurchaseQuantity = ""),
        (this.addForm.acceptingThePrice = ""),
        (this.addForm.groupEndTime = ""),
        (this.addForm.productPrice = ""),
        (this.addForm.productName = ""),
        (this.addForm.isProductShelves = ""),
        (this.addForm.fileType = ""),
        (this.addForm.productPhotoAlbums = []),
        (this.addForm.productPriceDataList = []),
        (this.addForm.formFileList1 = []), // 显示上传文件
        (this.addForm.formFileList2 = []); // 显示上传文件
    },
    handleDeleteAllDetailsUpdate() {
      this.editForm.productPriceDataList = undefined;
      console.log(
        "清空后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },
    handleAddDetailsUpdate() {
      if (this.editForm.productPriceDataList == undefined) {
        this.editForm.productPriceDataList = new Array();
      }
      let obj = {};
      obj.productSpecifications = "";
      obj.productSpecificationsMin = "";
      obj.productSpecificationsMax = "";
      obj.productPrice = "";
      obj.productSendIntegral = "";
      this.editForm.productPriceDataList.push(obj);
      console.log(
        "增加后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },
    handleDeleteDetailsUpdate() {
      if (this.checkedDetail.length == 0) {
        this.$alert("请先选择要删除的数据", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        this.editForm.productPriceDataList.splice(
          this.checkedDetail[0].xh - 1,
          1
        );
      }
      console.log(
        "删除后规格列表：" + JSON.stringify(this.editForm.productPriceDataList)
      );
    },

    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/group/queryGroupProduct",
        this.queryInfo
      );
      console.log("结果：" + res);
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
    },
    timestampToTime(row) {
      let date = new Date(row.groupEndTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    //修改用户状态
    async userStateChanged(data, b) {
      const { data: res } = await this.$http.post(
        "/chemical/group/updateGroupProduct",
        b
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
    },
    // 监听添加用户
    addDialogClosed() {
      console.log("进来了:");
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },
    handlePreview(file) {
      console.log(file);
    },
    handleRemoveProductContent(file, formFileList1) {
      this.addForm.formFileList1 = formFileList1;
      console.log("移除之后formFileList1：" + this.addForm.formFileList1);
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductMainProduct(file, formFileList2) {
      this.addForm.formFileList2 = formFileList2;
      console.log("移除之后formFileList2：" + this.addForm.formFileList2);
    },
    handleRemove(file, productPhotoAlbums) {
      this.addForm.productPhotoAlbums = productPhotoAlbums;
      console.log(
        "移除之后productPhotoAlbums：" + this.addForm.productPhotoAlbums
      );
    },
    handleRemoveUpdate(file, productPhotoAlbums) {
      this.editForm.productPhotoAlbums = productPhotoAlbums;
      console.log(
        "移除之后productPhotoAlbums：" + this.editForm.productPhotoAlbums
      );
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductContentUpdate(file, formFileList1) {
      this.editForm.formFileList1 = formFileList1;
      console.log("移除之后formFileList1：" + formFileList1);
      console.log("移除之后file：" + JSON.stringify(file));
    },
    handleRemoveProductMainProductUpdate(file, formFileList2) {
      this.editForm.formFileList2 = formFileList2;
      console.log("移除之后formFileList2：" + this.editForm.formFileList2);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    startvideo() {
      if (this.$refs.startvideo.paused) {
        this.$refs.startvideo.play();
      } else {
        this.$refs.startvideo.play();
      }
    },
    startPic() {},
    // 允许上传文件个数验证
    formHandleExceed(files, formFileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + formFileList.length} 个文件`
      );
    },
    async getProductList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryNaturalProduct",
        this.queryProductInfo
      );
      console.log("结果：" + res);
      this.productList = res.resultBody.data.list; // 将返回数据赋值
    },
    // 上传文件
    async handleUploadForm1(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.addForm.formFileList1 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productContent: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.addForm.formFileList1 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm2(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.addForm.formFileList2 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productMainImage: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.addForm.formFileList2 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm3(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        console.log("res.resultBody.data:" + res.resultBody.data);
        thiz.addForm.productPhotoAlbums.push({
          fileType: thiz.addForm.fileType,
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productFileUrl: res.resultBody.data,
          url: res.resultBody.data,
        });
        thiz.uploadFormFileList = [];
        console.log(
          "thiz.addForm" + JSON.stringify(thiz.addForm.productPhotoAlbums)
        );
      } else {
        thiz.addForm.productPhotoAlbums = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },

    // 上传文件
    async handleUploadForm4(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");

        thiz.editForm.formFileList1.push({
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productContent: res.resultBody.data,
          url: res.resultBody.data,
        });
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.formFileList1 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm5(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        thiz.editForm.formFileList2 = [
          {
            name: res.resultBody.data.split("/")[
              res.resultBody.data.split("/").length - 1
            ],
            productMainImage: res.resultBody.data,
            url: res.resultBody.data,
          },
        ];
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.formFileList2 = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },
    // 上传文件
    async handleUploadForm6(param) {
      var thiz = this;
      var data = new FormData();
      data.append("type", "4"); // 额外参数
      data.append("files", param.file);
      var loading = thiz.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("/pipi/sys/addFile", data);
      if (res.resultCode == 200) {
        thiz.$message.success("上传文件成功");
        console.log("res.resultBody.data:" + res.resultBody.data);
        thiz.editForm.productPhotoAlbums.push({
          fileType: this.editForm.fileType,
          name: res.resultBody.data.split("/")[
            res.resultBody.data.split("/").length - 1
          ],
          productFileUrl: res.resultBody.data,
        });
        console.log(
          "this.editForm" + JSON.stringify(this.editForm.productPhotoAlbums)
        );
        thiz.uploadFormFileList = [];
      } else {
        thiz.editForm.productPhotoAlbums = [];
        thiz.uploadFormFileList = [];
        thiz.$message.error("上传文件失败");
      }
      loading.close();
    },

    addUser() {
      if (this.addForm.fileType == 2) {
        if (this.addForm.productPhotoAlbums.length > 1) {
          return this.$message.info("相册类型选择的是视频，只能上传一个文件");
        }
      }

      console.log("====================================");
      console.log(this.addForm);
      console.log("====================================");

      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        // 发起请求
        this.addForm.productContent = this.addForm.formFileList1[0].url;
        this.addForm.productMainImage = this.addForm.formFileList2[0].url;
        const { data: res } = await this.$http.post(
          "/chemical/group/addGroupProduct",
          this.addForm
        );
        if (res.resultCode != "200") {
          return this.$message.error(res.resultMsg);
        }
        this.$message.success("操作成功！！！");
        //隐藏
        this.addDialogVisible = false;
        this.getUserList();
      });
    },
    // 展示修改框
    showEditDialog(data) {
      console.log("data" + JSON.stringify(data));
      var obj = {};
      obj.name = data.productContent.split("/")[
        data.productContent.split("/").length - 1
      ];
      obj.productContent = data.productContent;
      obj.url = data.productContent;
      var arr = [];
      arr.push(obj);
      data.formFileList1 = arr;

      var obj1 = {};
      obj1.name = data.productMainImage.split("/")[
        data.productMainImage.split("/").length - 1
      ];
      obj1.productMainImage = data.productMainImage;
      obj1.url = data.productMainImage;
      var arr1 = [];
      arr1.push(obj1);
      data.formFileList2 = arr1;
      console.log("data" + JSON.stringify(data));
      this.editForm = data;
      // this.editForm.productPriceDataList = data.productPriceDataList;
      if (this.editForm.productPhotoAlbums.length > 0) {
        this.editForm.fileType = this.editForm.productPhotoAlbums[0].fileType;
      }
      this.editForm.productType = parseInt(data.productType);

      for (var i = 0; i < this.editForm.productPhotoAlbums.length; i++) {
        var productFileUrl = this.editForm.productPhotoAlbums[i].productFileUrl;
        var filename = productFileUrl.split("/")[
          productFileUrl.split("/").length - 1
        ];
        this.editForm.productPhotoAlbums[i].name = filename;
      }

      this.editForm.selectValue = [data.productNaturalId, data.skuId];

      console.log("====================================");
      console.log(this.editForm);
      console.log("====================================");

      this.editDialogVisible = true;
    },
    // 关闭窗口
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 确认修改
    editUserInfo() {
      if (this.editForm.fileType == 2) {
        if (this.editForm.productPhotoAlbums.length > 1) {
          return this.$message.info("相册类型选择的是视频，只能上传一个文件");
        }
      }
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        this.editForm.productContent = this.editForm.formFileList1[0].url;
        this.editForm.productMainImage = this.editForm.formFileList2[0].url;
        for (var i = 0; i < this.editForm.productPhotoAlbums.length; i++) {
          this.editForm.productPhotoAlbums[i].fileType = this.editForm.fileType;
        }
        const { data: res } = await this.$http.post(
          "/chemical/group/updateGroupProduct",
          this.editForm
        );
        console.log(res);
        if (res.resultCode != "200")
          return this.$message.error(res.resultMsg || "操作失败！！！");
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 删除按钮
    async deleteEditDialog(productNaturalId) {
      // 弹框
      const confirmResult = await this.$confirm(
        "此操作将删除该商品, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 成功删除为confirm 取消为 cancel
      if (confirmResult != "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$http.post(
        "/chemical/natural/deleteNaturalProduct",
        productNaturalId
      );
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },
    async getTypeList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/typeList",
        this.queryTypeInfo
      );
      console.log("结果：" + res);
      this.typeList = res.resultBody.data.list; // 将返回数据赋值
    },
    async getWarehouseList() {
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/basic/warehouseList",
        this.queryWarehouseInfo
      );
      console.log("结果：" + res);
      this.warehouseList = res.resultBody.data.list; // 将返回数据赋值
    },
    handleProductChange() {
      this.addForm["productNaturalId"] = this.addForm.selectValue[0];
      this.addForm["skuId"] = this.addForm.selectValue[1];

      for (let index = 0; index < this.productList.length; index++) {
        const product = this.productList[index];
        var flag = false;
        for (let j = 0; j < product.sku.length; j++) {
          const skuObj = product.sku[j];
          if (skuObj.id == this.addForm["skuId"]) {
            flag = true;
            this.addForm["attr"] = skuObj["sku"];
            break;
          }
        }
        if (flag) {
          break;
        }
      }
    },
    formatForCas(list) {
      var result = [];
      list.forEach((product) => {
        var obj = {};
        obj["value"] = product["productNaturalId"];
        obj["label"] = product["productName"];
        obj["children"] = [];
        product.sku.forEach((sku) => {
          var skuObj = {};
          skuObj["value"] = sku["id"];
          skuObj["label"] = sku["sku"] || product["productName"];
          obj["children"].push(skuObj);
        });
        result.push(obj);
      });
      return result;
    },
    showAddGroupDataDialog() {
      this.addForm = {
        content: "",
        groupTotal: "",
        groupMaxCount: "",
        acceptingThePrice: "",
        groupEndTime: "",
        productPrice: "",
        productName: "",
        isProductShelves: "",
        fileType: "1",
        minPurchaseQuantity: "",
        productPhotoAlbums: [],
        productPriceDataList: [],
        formFileList1: [], // 显示上传文件
        formFileList2: [], // 显示上传文件
        score: "",
        selectValue: [],
        productNaturalId: "",
        skuId: "",
        attr: "",
      };
      this.addDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.a {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.b {
  width: 60%;
  margin-right: 20px;
}
</style>
