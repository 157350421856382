import AddPriceDialog from './index.vue'
import Vue from 'vue'

const ModalAddPrice = Vue.extend(AddPriceDialog)
const showPriceDailog = function(options) { // 接收配置
  const min = 0
  const Instance = new ModalAddPrice({ // 实例化组件
    data: { // 给data的变量赋值
      priceForm: {
        min: (options && options.min) || min,
        max: ''
      }
    }
  })
  const vm = Instance.$mount() // 挂载
  document.body.appendChild(vm.$el) // 插入body
  return vm.init() // 执行初始化方法, 返回的是一个promise
}
export default {
  install: (Vue) => { // 暴露install方法供Vue.use()调用
    Vue.prototype.$showPriceDailog = showPriceDailog // 挂到Vue的原型上使用
  }
}
