<template>
  <el-form ref="addForm" :model="addForm" :rules="addRules">
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form-item label="规格名称:" prop="name" label-width="100px">
          <el-input v-model="addForm.name" placeholder="请输入商品规格" />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item
          v-for="(item, index) in addForm.item"
          :key="item.value"
          :label="item.value"
          label-width="100px"
        >
          <i
            class="el-icon-circle-close"
            style="margin-left:-5px;margin-right:10px"
            @click="addForm.item.splice(index, 1)"
          />
          <el-tag
            v-for="(tag, indexTag) in item.item"
            :key="tag"
            closable
            :disable-transitions="false"
            style="margin-right: 10px"
            @close="item.item.splice(indexTag, 1)"
          >
            {{ tag }}
          </el-tag>
          <el-button size="small" @click="showInput(index)"
            >新增属性值</el-button
          >
        </el-form-item>
      </el-col>
      <el-col v-if="isEdit" :span="8">
        <el-form-item label="规格:" label-width="100px">
          <el-input v-model="inputAttrDetail.value" placeholder="请输入规格" />
        </el-form-item>
      </el-col>
      <el-col v-if="isEdit" :span="8">
        <el-form-item label="规格值:" label-width="100px">
          <el-input
            v-model="inputAttrDetail.item"
            placeholder="请输入规格值"
          />
        </el-form-item>
      </el-col>
      <el-col v-if="isEdit" :span="8">
        <el-form-item>
          <el-button type="primary" class="editBtn" @click="confilmClick"
            >确定</el-button
          >
          <el-button class="editBtn" @click="isEdit = false">取消</el-button>
        </el-form-item>
      </el-col>
      <el-col v-if="!isEdit" :span="24">
        <el-form-item label-width="100px">
          <el-button type="primary" @click="isEdit = true"
            >添加新规格</el-button
          >
        </el-form-item>
      </el-col>
      <el-col :span="24" style="display:flex;justify-content: flex-end">
        <el-form-item>
          <el-button @click="$emit('close')">取消</el-button>
          <el-button type="primary" @click="submitClick">确定</el-button>
        </el-form-item>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="inputVisible"
      title="新增属性值"
      :destroy-on-close="true"
      append-to-body
    >
      <el-input
        v-if="inputVisible"
        ref="saveTagInput"
        v-model="inputTagValue"
        class="input-new-tag"
        placeholder="请输入属性值"
        @keyup.enter.native="handleInputConfirm(addAttrValueIndex)"
      />
      <template slot="footer">
        <el-button
          type="primary"
          class="editBtn"
          @click="handleInputConfirm(addAttrValueIndex)"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </el-form>
</template>

<script>
export default {
  props: {
    addForm: {
      type: Object,
      default: function() {
        return {
          name: "",
          item: [],
        };
      },
    },
    isAdd: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
  },
  data() {
    return {
      isEdit: false,
      inputTagValue: "",
      inputVisible: false,
      addRules: {
        name: [{ required: true, message: "请输入规格名称", trigger: "blur" }],
      },
      inputAttrDetail: {
        value: "",
        item: "",
      },
      addAttrValueIndex: 0,
    };
  },
  methods: {
    handleInputConfirm(index) {
      const inputValue = this.inputTagValue;
      if (inputValue) {
        if (this.addForm.item[index].item.indexOf(inputValue) >= 0) {
          this.$message.error("属性值已存在");
          return;
        }
        this.addForm.item[index].item.push(inputValue);
      } else {
        this.$message.error("请输入属性值");
      }
      this.inputVisible = false;
      this.inputTagValue = "";
    },
    confilmClick() {
      console.log('====================================');
      console.log(this.inputAttrDetail);
      console.log('====================================');
      const { value, item } = this.inputAttrDetail;
      if (value.length === 0 || item.length === 0) {
        this.$message.error("请输入规格和规格值");
        return;
      }
      var attr = {
        value: value,
        item: [item],
      };

      // 查重
      var flag = true;
      for (let index = 0; index < this.addForm.item.length; index++) {
        const attr = this.addForm.item[index];
        if (value === attr.value) {
          flag = false;
          break;
        }
      }

      if (!flag) {
        this.$message.error("规格已存在");
        return;
      }

      this.addForm.item.push(attr);
      this.isEdit = false;
    },
    showInput(index) {
      this.addAttrValueIndex = index;
      this.inputVisible = true;
    },
    async submitClick() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          var attrList = this.addForm.item;
          if (!attrList.length) {
            this.$message.error("请输入最少一条规格");
            return;
          }

          const loading = this.$loading({
            lock: true,
            text: this.isAdd ? "上传中，请稍候..." : "更新中，请稍候...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          if (this.isAdd) {
            this.$http
              .post("/chemical/attr/add", this.addForm)
              .then(() => {
                loading.close();
                this.visibleDialog = false;
                this.$message.success("更新成功");
                this.$emit("addAttrSuccess", this.addForm);
              })
              .catch((err) => {
                loading.close();
                console.log(err);
              });
          } else {
            this.$http
              .post("/chemical/attr/update", this.addForm)
              .then(() => {
                loading.close();
                loading.close();
                this.visibleDialog = false;
                this.$message.success("更新成功");
                this.$emit("addAttrSuccess");
              })
              .catch((err) => {
                loading.close();
                console.log(err);
              });
          }
        } else {
          console.log("error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
