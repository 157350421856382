<template>
  <div class="login_container" :style="conTop">
    <div class="login_box">
      <div class="avatar_box_top">
        <!-- <img src="../assets/logo.png" alt class="logo_img" /> -->
        <label class="system_name">凯米科管理系统</label>
      </div>
      <div class="avatar_box_middle">
        <el-form
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginRules"
          class="login_form"
          label-width="0px"
        >
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              prefix-icon="iconfont icondenglu" placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              prefix-icon="iconfont iconmima" placeholder="请输入密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button type="primary" @click="login">登录</el-button>
            <el-button type="info" @click="resetLoginForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="login_footer">
      COPYRIGHT © 2020 XXXXXX 版权所有 保留一切权利 | 隐私政策 | 法律声明 |
      皖XX-0000000 | 皖ICP备0000000000号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conTop: {
        backgroundImage: "url(" + require("../assets/2.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 5, max: 15, message: "长度在 5 到 8 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 8, message: "密码为 6~8 位", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用get请求
        const { data: res } = await this.$http.post(
          "/pipi/sys/login",
          this.loginForm
        );
        if (res.resultCode == 200) {
          window.sessionStorage.setItem("token", res.resultBody.token); // session 放置
          window.sessionStorage.setItem("userInfo",  JSON.stringify(res.resultBody.userInfo)); // session 放置
          window.sessionStorage.setItem(
            "sysPermissions",
            res.resultBody.sysPermissions
          ); // session 放置
          console.log("parent"+parent);
          window.sessionStorage.setItem("res", res); // session 放置
          // console.log(JSON.stringify("菜单"+res.resultBody.sysPermissions))
          this.$message.success("登陆成功！！！");
          var pathUrl = "/"+res.resultBody.sysPermissions[0].sysPermissions[0].url;
          this.$router.push({ path: pathUrl});
          window.sessionStorage.setItem("activePath",res.resultBody.sysPermissions[0].sysPermissions[0].url);
          window.sessionStorage.setItem("openeds",res.resultBody.sysPermissions[0].id);
        } else {
          this.$message.error(res.resultMsg);
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
/* 跟节点样式 */
.login_container {
  min-height:100%;
  margin:0;
  padding:0;
  position:relative;
}
/* 登录框 */
.login_box {
  text-align: center;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.avatar_box_right {
  font-size: 40px;
}

.avatar_box_middle {
  margin-top: 30px;
}

.system_name {
  font-size: 60px;
  color: #fdfafa;
  margin-top: 50px;
}
.logo_img {
  width: 13%;
  border-radius: 50%;
}
.login_footer {
  flex: 0;
  text-align: center;
  font-size: 14px;
  color: #fdfafa;
  position:absolute;bottom:0;width:100%;height:3%;
}
</style>