<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
 // @ is an alias to /src
  import Home from "./components/Home.vue";

  export default {
    components: {
      Home
    }
  };
</script>

<style>
html,
  body,
  #app {
    height: 100%;
    /* padding: 0;
    margin: 0; */
  }
</style>
