import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import User from '../components/User.vue'
import Role from '../components/Role.vue'
import Message from '../components/Message.vue'
import Type from '../components/Type.vue'
import ShufflingFigure from '../components/ShufflingFigure.vue'
import Classification from '../components/Classification.vue'
import Inventory from '../components/Inventory.vue'
import EnterpriseAudit from '../components/EnterpriseAudit.vue'
import Members from '../components/Members.vue'
import NaturalProduct from '../components/NaturalProduct.vue'
import Attr from '../components/Attr.vue'
import NaturalOrder from '../components/NaturalOrder.vue'
import GroupProduct from '../components/GroupProduct.vue'
import GroupOrderProduct from '../components/GroupOrderProduct.vue'
import ScoreProduct from '../components/ScoreProduct.vue'
import ScoreOrderProduct from '../components/ScoreOrderProduct.vue'
import PrivacyAgreement from '../components/PrivacyAgreement.vue'
import NaturalProductAudit from '../components/NaturalProductAudit.vue'
import GroupProductAudit from '../components/GroupProductAudit.vue'
Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/users',
    children: [
      { path:'/users', component: User},
      { path:'/roles', component: Role},
    ],
  },
  {
    path: '/home',
    component: Home,
    redirect: '/shufflingFigure',
    children: [
      { path:'/shufflingFigure', component: ShufflingFigure},
      { path:'/type', component: Type},
      { path:'/classification', component: Classification},
      { path:'/inventory', component: Inventory},
      { path:'/enterpriseAudit', component: EnterpriseAudit},
      { path:'/privacyAgreement', component: PrivacyAgreement},
      { path:'/naturalPriceAudit', component: NaturalProductAudit},
      { path:'/groupPriceAudit', component: GroupProductAudit},
      { path:'/message', component: Message},
    ],
  },
  {
    path: '/home',
    component: Home,
    redirect: '/members',
    children: [
      { path:'/members', component: Members},
      { path:'/naturalProduct', component: NaturalProduct},
      { path:'/attr', component: Attr},
      { path:'/naturalOrder', component: NaturalOrder},
      { path:'/groupProduct', component: GroupProduct},
      { path:'/groupOrderProduct', component: GroupOrderProduct},
      { path:'/scoreProduct', component: ScoreProduct},
      { path:'/scoreOrderProduct', component: ScoreOrderProduct}
    ],
  }

]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to:将要访问的路径
    // from:从哪里访问的路径
    // next:之后要做的任务，是一个函数
    //    next（）放行， next（'/URL'）强制跳转的路径。
    if (to.path == '/login') return next();// 访问路径为登录
    // 获取flag
    const token = window.sessionStorage.getItem("token");// session取值
    if (!token) return next('/login');// 没登录去登录
    next();
  })
  

export default router// 暴露出去





















