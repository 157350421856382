<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>业务管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="25">
        <el-col :span="4">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入手机号码"
            v-model="queryInfo.phone"
            clearable
            @clear="getUserList(1)"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入公司法人姓名"
            v-model="queryInfo.companyLegalPerson"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <!-- 搜索添加 -->
          <el-input
            placeholder="请输入公司名称"
            v-model="queryInfo.companyName"
            clearable
            @clear="getUserList"
          >
          </el-input>
        </el-col>
        <el-col :span="4.5">
          <el-date-picker
            v-model="queryInfo.time"
            type="datetime"
            clearable
            @clear="getUserList"
            placeholder="选择注册日期时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col v-if="showEdit" :span="3">
          <el-button icon="el-icon-download" @click="exportFile">导出</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="用户手机号码" prop="phone"></el-table-column>
        <!-- <el-table-column label="用户头像" prop="headPortrait">
          <template slot-scope="scope">
            <viewer>
              <img class="viewer-image" :src="scope.row.headPortrait">
            </viewer>
          </template>
        </el-table-column> -->
        <el-table-column label="公司名称" prop="companyName"></el-table-column>
        <el-table-column label="资质审核状态" prop="companyAudit">
          <template slot-scope="scope">
            <el-tabs >{{ getUserStatueDesc(scope.row) }}</el-tabs>
          </template>
        </el-table-column>
        <el-table-column label="账户状态">
          <template slot-scope="scope">
            <p v-if="scope.row.isDel == 1">已注销</p>
            <p v-else>正常</p>
          </template>
        </el-table-column>
        <el-table-column label="销售负责人" prop="seller"></el-table-column>
        <el-table-column label="剩余积分" prop="integral"> </el-table-column>
        <el-table-column
          label="注册时间"
          prop="createTime"
          :formatter="timestampToTime"
        ></el-table-column>
        <el-table-column v-if="showEdit" label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button v-if="scope.row.companyAudit != 0" type="text" size="small" @click="editUser(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <el-dialog title="更新用户信息" :visible.sync="dialogUserVisible">
      <el-form :model="editForm" :rules="editFormRules">
    <el-form-item label="公司名称" prop="companyName">
      <el-input v-model="editForm.companyName"></el-input>
    </el-form-item>
    <el-form-item label="销售负责人">
      <el-input v-model="editForm.seller" ></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogUserVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmClick">确 定</el-button>
  </div>
    </el-dialog>
  </div>
</template>
<script>

import XLSX from "xlsx";

export default {
  data() {
    return {
      // 请求数据
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      queryRoleInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      productList: [],
      warehouseList: [],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {},
      showEdit: false,
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        companyName: "",
        seller: ''
      },

      deFlag: "",
      // 验证规则
      addFormRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 5, max: 15, message: "长度在 5 到 8 个字符", trigger: "blur" },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 8, message: "长度在 6 到 8 个字符", trigger: "blur" },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
      },
      dialogUserVisible: false
    };
  },
  created() {
    const { id } = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.showEdit = id == 1 || id == 17;
    this.getUserList();
  },
  methods: {
     timestampToTime(row) {
      let date = new Date(row.createTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryUserData",
        this.queryInfo
      );
      console.log("结果：" + res);
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },

    getUserStatueDesc(user) {
      if (user.companyAudit == 0) {
        return '未填写'
      }
      if (user.companyAudit == 1) {
        return '未审核'
      }
      if (user.companyAudit == 2) {
        return '审核通过'
      }
      if (user.companyAudit == 3) {
        return '审核未通过'
      }
    },
    //修改用户状态
    async userStateChanged(data, b) {
      console.log(b);
      const { data: res } = await this.$http.post(
        "/chemical/natural/updateUserData",
        b
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
    },
    editUser(userData) {
      this.editForm = Object.assign({}, userData);
      this.dialogUserVisible = true
    },
    async confirmClick() {
      this.dialogUserVisible = false
      const { userId, companyName, seller } = this.editForm
      var params = {
        id: userId,
        companyName: companyName,
        seller: seller
      }
      const { data: res } = await this.$http.post(
        "/chemical/basic/updateUserSeller",
        params
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList()
    },
    // 监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields(); // 重置表单项
    },
    async exportFile() {
      // 获取所有用户
      var paramas = Object.assign(this.queryInfo, {})
      paramas['pageNo'] = "-1"
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryUserData",
        paramas
      );
      if (res.resultCode != "200") {
        //userinfo.state = !userinfo.state;
        return this.$message.error("操作失败！！！");
      }
      
      var sheetData = [];
      res.resultBody.data.list.forEach(user => {
        const { phone, companyName, isDel, seller} = user
        var dict = {
          '手机号': phone,
          '公司名称': companyName || '',
          '资质审核状态': this.getUserStatueDesc(user),
          '账户状态': isDel == 1 ? '已注销' : '正常',
          '销售负责人': seller || '',
          '注册时间': this.timestampToTime(user)
        }
        sheetData.push(dict)
      });
      var date = new Date;
      let fileName = "用户列表" + this.timestampToTime({createTime: date});
      // 将由对象组成的数组转化成sheet
      const sheet = XLSX.utils.json_to_sheet(sheetData);
      // 百分数和数字更改为数字类型
      Object.keys(sheet).forEach((key) => {
        if (sheet[key].v) {
          const val = sheet[key].v;
          if (!isNaN(val)) {
            sheet[key].t = "n";
          }
          // if (val.lastIndexOf("%") === val.length - 1) {
          //   sheet[key].t = "n";
          //   sheet[key].z = "0.00%";
          //   sheet[key].v = Number(val.substring(0, val.length - 1)) / 100;
          // }
        }
      });
      // 创建虚拟的workbook
      const wb = XLSX.utils.book_new();
      // 把sheet添加到workbook中
      XLSX.utils.book_append_sheet(wb, sheet, fileName);
      const workbookBlob = this.workbook2blob(wb);
      this.openDownload(workbookBlob, `${fileName}.xls`);
    },
    openDownload(blob, fileName) {
      if (typeof blob === "object" && blob instanceof Blob) {
        blob = URL.createObjectURL(blob); // 创建blob地址
      }
      const aLink = document.createElement("a");
      aLink.href = blob;
      // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
      aLink.download = fileName || "";
      let event;
      if (window.MouseEvent) event = new MouseEvent("click");
      //   移动端
      else {
        event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
      }
      aLink.dispatchEvent(event);
    },
    workbook2blob(workbook) {
      // 生成excel的配置项
      const wopts = {
        // 要生成的文件类型
        bookType: "xlsx",
        // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        bookSST: false,
        type: "binary",
      };
      const wbout = XLSX.write(workbook, wopts);
      // 将字符串转ArrayBuffer
      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      return blob;
    },
  },
};
</script>
<style lang="less" scoped>
</style>