<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>业务管理</el-breadcrumb-item>
      <el-breadcrumb-item>拼团订单</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区 -->
    <el-card>
      <el-row :gutter="15">
        <el-col :span="3">
          <!-- 搜索添加 -->
          <el-select
            v-model="queryInfo.orderState"
            clearable
            placeholder="订单状态"
            @clear="getUserList(1)"
          >
            <el-option
              v-for="item in orderStateList"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-col>
        <el-col :span="0.1">
          <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-col>
        <el-col :span="1">
          <el-button icon="el-icon-refresh" @click="getUserList(1)"></el-button>
        </el-col>
      </el-row>
      <!-- 用户列表 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="订单号" prop="orderNum" width="180px"></el-table-column>
        <el-table-column width="160px"
          label="下单时间"
          prop="createTime"
          :formatter="timestampToTime1"
        ></el-table-column>
        <el-table-column label="订单状态" prop="orderState">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.orderState == 1">拼团中</el-tabs>
            <el-tabs v-if="scope.row.orderState == 2">拼团失败</el-tabs>
            <el-tabs v-if="scope.row.orderState == 3">拼团成功</el-tabs>
            <el-tabs v-if="scope.row.orderState == 4">待收货</el-tabs>
            <el-tabs v-if="scope.row.orderState == 5">已完成</el-tabs>
            <el-tabs v-if="scope.row.orderState == 6">已确认</el-tabs>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="productName"> </el-table-column>
        <el-table-column label="金额" prop="orderPrice"> </el-table-column>
        <el-table-column label="订单数量" prop="groupNum"> </el-table-column>
        <el-table-column width="120px"
          label="拼团结束时间"
          prop="groupEndTime"
          :formatter="timestampToTime"
        ></el-table-column>
        <el-table-column label="下单用户" prop="phone"> </el-table-column>
        <el-table-column label="法人姓名" prop="companyLegalPerson">
        </el-table-column>
        <el-table-column label="公司名称" prop="companyName" width="140px"> </el-table-column>
        <el-table-column label="订单收货地址" prop="orderAddress" width="200px">
        </el-table-column>
        <el-table-column label="配送方式" prop="distributionType">
          <template slot-scope="scope">
            <el-tabs v-if="scope.row.distributionType == 1">配送上门</el-tabs>
            <el-tabs v-if="scope.row.distributionType == 2">到库自提</el-tabs>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
     <el-dialog
      title="确认订单"
      :visible.sync="editDialogVisibleMoney"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editFormMoney"
        :rules="editFormMoneyRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="运费（元）" prop="carriage1">
          <el-input v-model="editFormMoney.carriage1"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisibleMoney = false">取 消</el-button>
        <el-button type="primary" @click="orderStateAuditMoney"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="发货"
      :visible.sync="editDialogVisible"
      width="30%"
      @colse="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item
          label="选择仓库"
          prop="warehouseId"
          v-if="editForm.distributionType == 1"
        >
          <el-select
            :data="warehouseList"
            placeholder="仓库"
            clearable
            v-model="editForm.warehouseId"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.warehouseId"
              :label="item.name"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="选择仓库"
          prop="warehouseId"
          v-if="editForm.distributionType == 2"
        >
          <el-select
            :disabled="true"
            :data="warehouseList"
            placeholder="仓库"
            clearable
            v-model="editForm.warehouseId"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.warehouseId"
              :label="item.name"
              :value="item.warehouseId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editOrderInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 请求数据
      queryInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      orderStateList: [
        { key: "1", display_name: "拼团中" },
        { key: "2", display_name: "拼团失败" },
        { key: "3", display_name: "拼团成功" },
        { key: "4", display_name: "待收货" },
        { key: "5", display_name: "已完成" },
      ],
      queryRoleInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      productList: [],
      warehouseList: [],
      userlist: [], // 用户列表
      total: 0, // 最大数据记录
      orderTotalPrice: "",
      addDialogVisible: false, // 对话框显示
      // 添加用户表单项
      addForm: {},
      // 控制修改用户对话框显示/隐藏
      editDialogVisible: false,
      // 修改用户信息
      editForm: {
        userNum: "0",
      },
      editFormMoney: {
        carriage1: "",
        orderGroupId: "",
        orderState: "",
      },
      editDialogVisibleMoney: false,
      deFlag: "",
      // 验证规则
      addFormRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 5, max: 15, message: "长度在 5 到 8 个字符", trigger: "blur" },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 8, message: "长度在 6 到 8 个字符", trigger: "blur" },
        ],
      },
      // 修改用户表单验证规则
      editFormRules: {
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 8, message: "长度在 6 到 8 个字符", trigger: "blur" },
        ],
      },
       editFormMoneyRules: {
        // passWord: [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        //   { min: 6, max: 8, message: "长度在 6 到 8 个字符", trigger: "blur" },
        // ],
      },
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    timestampToTime(row) {
      let date = new Date(row.groupEndTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    timestampToTime1(row) {
      let date = new Date(row.createTime);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    async getUserList(param) {
      console.log("param参数" + param);
      if (1 == param) {
        this.queryInfo.pageNo = 1;
      }
      // 调用post请求
      const { data: res } = await this.$http.post(
        "/chemical/group/queryGroupOrder",
        this.queryInfo
      );
      for(let index = 0; index < res.resultBody.data.list.length; index++) {
          var item = res.resultBody.data.list[index];
         if (item.distributionType == 1) {
          var json = JSON.parse(item.orderAddress);
          item.orderAddress = json.province+" "+json.city+" "+json.county+" "+json.detail+" "+json.name+" "+json.phone
         }else {
          item.orderAddress = '自提' + '(' + item.name + ')';
         }
      }
      this.userlist = res.resultBody.data.list; // 将返回数据赋值
      this.total = res.resultBody.data.total; // 总个数
      this.orderTotalPrice = res.resultBody.orderTotalPrice;
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList(); // 数据发生改变重新申请数据
    },
    // 监听pageNum改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage;
      this.getUserList(); // 数据发生改变重新申请数据
    },
     // 展示修改框
    async showEditDialogMoney(data) {
      this.editFormMoney = data;
      this.editDialogVisibleMoney = true;
    },
       // 关闭窗口
    editDialogClosed() {
      this.editFormMoney = {};
      this.$refs.editFormRef.resetFields();
    },
    // 审核按钮
    async orderStateAudit(data, orderState) {
      //审核通过
      if (6 == orderState) {
        //审核通过
        // 弹框
        const confirmResult = await this.$confirm(
          "确认订单, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => err);
        // 成功删除为confirm 取消为 cancel
        if (confirmResult != "confirm") {
          return this.$message.info("已取消");
        }
      }

      if (4 == orderState) {
        const confirmResult = await this.$confirm(
        "拼团订单发货, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // 成功删除为confirm 取消为 cancel
      if (confirmResult != "confirm") {
        return this.$message.info("已取消审核");
      }
      }

      var params = {
        orderState: orderState,
        orderGroupId: data.orderGroupId,
      };
      if(orderState == 6){
        params.carriage = 0
      }
      const { data: res } = await this.$http.post(
        "/chemical/group/updateGroupOrder",
        params
      );
      if (res.resultCode != "200") {
        return this.$message.error("操作失败！！！");
      }
      this.$message.success("操作成功！！！");
      this.getUserList();
    },// 确认修改
    editOrderInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        var data = {
          orderState: "4",
          distributionType: this.editForm.distributionType,
          warehouseId: this.editForm.warehouseId,
          orderGroupId: this.editForm.orderGroupId,
        };
        // 发起请求
        const { data: res } = await this.$http.post(
          "/chemical/group/updateGroupOrder",
          data
        );
        console.log(res);
        if (res.resultCode != "200") return this.$message.error(res.resultMsg);
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisible = false;
        this.getUserList();
      });
    },
    // 监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields(); // 重置表单项
    }, // 展示修改框
    async showEditDialog(data) {
      this.editForm = data;
      this.getWarehouseList(this.editForm.productNaturalId);
      this.editDialogVisible = true;
    },
     orderStateAuditMoney() {
      this.$refs.editFormRef.validate(async (valid) => {
        console.log(valid);
        if (!valid) return;
        // 发起请求
        var data = {
          orderGroupId: this.editFormMoney.orderGroupId,
          orderState: "6",
          carriage: this.editFormMoney.carriage1,
        };
        const { data: res } = await this.$http.post(
          "/chemical/group/updateGroupOrder",
          data
        );
        console.log(res);
        if (res.resultCode != "200") return this.$message.error(res.resultMsg);
        this.$message.success("操作成功！！！");
        //隐藏
        this.editDialogVisibleMoney = false;
        this.getUserList();
      });
    },async getWarehouseList(productNaturalId) {
      // 调用post请求
      var data = {
        productNaturalId: productNaturalId,
        pageNo: "1",
        pageSize: "1000",
      };
      const { data: res } = await this.$http.post(
        "/chemical/natural/queryWarehouse",
        data
      );
      console.log("结果：" + res);
      this.warehouseList = res.resultBody.data.list; // 将返回数据赋值
    },
  },
};
</script>
<style lang="less" scoped>
.card-header {
  text-align: center;
}

.card-body {
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
}

.card-body-left {
  width: 50%;
}

.card-body-right {
  width: 50%;
  color: rgb(64, 174, 247);
  // float: left;
}
</style>